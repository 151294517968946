import {useParams} from "react-router-dom";
import {useEffect, useRef, useState} from "react";
import {getCardsFromReference, getCardsFromTag} from "../api/api";
import "./card_overview.css";
import {SRCard} from "../spaced_repetition/interface";
import {CardData} from "../spaced_repetition/interface";
import {sentenceParser} from "../spaced_repetition/utils";
import {useLocation, Link} from "react-router-dom";


export function processCards (cards: CardData[] = [], fields: string[]=[]): SRCard[] {
    let formattedCards: SRCard[] = [];
    console.log(cards);
    for (const card of cards) {
        if (fields.length > 0) {
            if (fields.includes(card.field)) {
                formattedCards.push({
                    cardID: card.cardID,
                    front: card.data.front,
                    back: card.data.back,
                    cardType: card.cardType,
                    tags: card.tags.split(";").filter((tag) => tag.length >0 ),
                    referenceUrl: card.url,
                    referenceTitle: card.title,
                    referenceLastUpdated: card.last_updated ? new Date(card.last_updated.split("T")[0]) : null,
                    referenceType: card.type,
                    reviewType: card.reviewType,
                    path: card.path,
                    fields: card.field ? card.field.split(",") : [],
                    referenceID: parseInt(card.referenceIDs),
                    easinessFactor: card.easinessFactor,
                    referenceAuthors: card.authors ? card.authors.split(";") : [],
                    images: card.data.images.split(";"),
                    programmingLanguage: card.data.programmingLanguage ?? "",
                    languageOut: card.data.languageOut ?? "",
                    languageIn: card.data.languageIn ?? "",
                    POSin: card.data.POSin ?? "",
                    POSout: card.data.POSout ?? ""
                })
            }
        } else {
            formattedCards.push({
                cardID: card.cardID,
                front: card.data.front,
                back: card.data.back,
                cardType: card.cardType,
                tags: card.tags.split(";").filter((tag) => tag.length >0 ),
                referenceUrl: card.url,
                referenceTitle: card.title,
                referenceLastUpdated: card.last_updated ? new Date(card.last_updated.split("T")[0]) : null,
                referenceType: card.type,
                reviewType: card.reviewType,
                path: card.path,
                fields: card.field ? card.field.split(",") : [],
                referenceID: parseInt(card.referenceIDs),
                easinessFactor: card.easinessFactor,
                referenceAuthors: card.authors ? card.authors.split(";") : [],
                images: card.data.images.split(";"),
                programmingLanguage: card.data.programmingLanguage ?? "",
                languageOut: card.data.languageOut ?? "",
                languageIn: card.data.languageIn ?? "",
                POSin: card.data.POSin ?? "",
                POSout: card.data.POSout ?? ""
            })
        }
    }
    console.log(formattedCards)
    formattedCards = formattedCards.filter((card) => !card.tags.includes("workflow/SR/improve"))
    return formattedCards;
}

interface CardUnitProps {
    card: SRCard;

}


const RAINBOW = [
    ['#e55d87', '#5fc3e4'],
    ['#dbd4b4', '#7aa1d2'],
    ['#3ca55c', '#b5ac49'],
    ['#348f50', '#56b4d3'],
    ['#da22ff', '#9733ee'],
    ['#ff6e7f', '#bfe9ff'],
    ['#e684ae', '#77a1d3'],
    ['#2b5879','#4e4376'],
    ['#e65c00', '#f9d423'],
    ['#73C8A9', '#373B44'],
    ['#24fe41','#fdfc47'],
    ['#83a4d4', '#b6fbff'],
    ['#fe8c00', '#f83600'],
    [ '#70e1f5', '#ffd194'],
    ['#9d50bb', '#6e48aa'],
    ['#f0c27b', '#4b1248'] ,
    [ '#ff4e50', '#f9d423'],
    [ '#fbd3e9', '#bb377d'],
    ['#c9ffbf', '#ffafbd'],
    ['#b993d6', '#8ca6db'],
    ['#f2709c', '#ff9472'],
    ['#de6262',  '#ffb88c'],
    ['#d53369', '#cbad6d'],
    ['#f857a6', '#ff5858'],
    ['#fc354c', '#0abfbc'],
    ['#414d0b', '#727a17'],
    ['#c04848', '#480048'],
    ['#5f2c82', '#49a09d'],
    ['#ec6f66', '#f3a183'],
    ['#dae2f8', '#d6a4a4'],
    ['#dc2424', '#4a569d'],
    ['#24c6dc', '#514a9d'],
    ['#283048', '#859398'],
    ['#3d7eaa', '#ffe47a'],
    ['#1cd8d2', '#93edc7'],
    ['#757f9a', '#d7dde8'],
    ['#5c258d', '#4389a2'],
    ['#134e5e', '#71b280'],
    ['#4776e6', '#8e54e9'],
    ['#614385', '#516395'],
    ['#eb3349', '#f45c43'],
    ['#dd5e89', '#f7bb97'],
    ['#4cb8c4', '#3cd3ad'],
    ['#1fa2ff', '#12d8fa'],
    ['#1d2b64', '#f8cdda']
    ,['#aa076b', '#61045f'],
    ['#ff512f', '#dd2476'],
    ['#f09819', '#edde5d'],
    ['#403b4a', '#e7e9bb']
]

function CardUnit({card}: CardUnitProps) {
    const unit = useRef();
    const [unitHeight, setUnitHeight] = useState<number>(0);
    const [rand, setRand] = useState<number>();

    useEffect(() => {
        if (unit && unit.current) {
            // @ts-ignore
            setUnitHeight(unit.current.clientHeight);
        }
    });

    useEffect(() => {
        const no = Math.ceil(Math.random()*(RAINBOW.length-1));
        setRand(no);
        console.log(no)
    },[]);

    return (// @ts-ignore
        <div ref={unit} style={{'grid-row-end': `span ${Math.ceil(unitHeight) + 6}`}} className="card-unit">
            <div className="card-unit-metadata-pane">
                {card && card.cardID}
            </div>


            {card && rand &&  card.cardType === "cloze" && <div className={"overview-card-definition"}>{sentenceParser(card.front.text.replace("_", card.back.text), "back", "back")}</div>}
            {card && rand && card.cardType === "definition" && <><div className="overview-card-term" style={{color: `${RAINBOW[rand][0]}`}}>{card.front.text}</div><div className="overview-card-text">{sentenceParser(card.back.text, "back", "back")}</div></>}
            {card && rand && card.cardType === "notation" && <><div className="overview-card-term" style={{color: `${RAINBOW[rand][0]}`}}>{sentenceParser(card.front.text, "back", "back")}</div><div className="overview-card-definition">{sentenceParser(card.back.text, "back", "back")}</div></>}
            {card && rand && card.cardType === "set" && <><div className="overview-card-definition">{sentenceParser(card.front.text, "back", "back")}</div><div className="overview-card-set"><ul>{card.back.text.split(";").map((item) => <li>{sentenceParser(item, "back", "back")}</li>)}</ul></div></>}
            {card && rand && card.cardType === "synonym" && <><div className="overview-card-term" style={{color: `${RAINBOW[rand][0]}`}}>{sentenceParser(card.front.text, "back", "back")}</div><div className="overview-card-definition">{sentenceParser(card.back.text, "back", "back")}</div></>}
            {card && rand && card.cardType === "visualization" && <><div className="overview-card-definition">{sentenceParser(card.front.text, "back", "back")}</div><div className="overview-card-image"><img src={`/images/cards/${card.back.text}`} /></div></>}
            {card && rand && card.cardType === "QA" && <><div className="overview-card-definition">{sentenceParser(card.front.text, "back", "back")}</div>{card.back.text.split(";").length > 1 ? <div className="overview-card-set"><ul>{card.back.text.split(";").map((item) => <li>{sentenceParser(item, "back", "back")}</li>)}</ul></div> : <div className="overview-card-text">{sentenceParser(card.back.text, "back", "back")}</div>}</>}


            <div className="card-unit-tag-pane">
                {card && rand && card.tags.length > 0 && card.tags.map((tag) => <Link to={`/cards/from_tag/${tag}`}><div style={{background: `linear-gradient(60deg, ${RAINBOW[rand][0]}, ${RAINBOW[rand][1]}`}} className="card-unit-tag">{tag}</div></Link>)}
            </div>
        </div>
    )
}

export function CardOverview() {
    const [cards, setCards] = useState<any[]>([]);
    const location = useLocation();
    let { id } = useParams();

    useEffect(() => {

        if (id) {
            loadCards();
        }

    }, [location.pathname]);

    async function loadCards() {
        let cards;
        if (location.pathname.includes("reference")) {
            // @ts-ignore
            cards = await getCardsFromReference(parseInt(id)).then((cards) => processCards(cards));
        } else {
            // @ts-ignore
            cards = await getCardsFromTag(id).then((cards) => processCards(cards));
        }

        console.log(cards);
        setCards([...cards]);
    }

    return  (
        <div className="card-overview">
            <div className="card-overview-container">
                {cards && cards.length > 1 && cards.map((card) => <CardUnit card={card} />)}
            </div>

        </div>
    )
}