import {useState} from "react";

export function AddTaskMenu() {
    const [unfolded, setUnfolded] = useState<boolean>();
    const TASK_CHOICES = ["research", "chore", "administration"]


    return (
        <div className="AddTaskMenu">
            <button className="add-task btn" onClick={(e) => setUnfolded(!unfolded)}>
                <h1>+</h1>
            </button>
            {unfolded &&
                <div className="task-choice-menu" onMouseLeave={(e) => setUnfolded(false)}>
                    {TASK_CHOICES.map((choice: string) => <button>{choice}</button>)}
                </div>
            }
        </div>
    )
}