import {CardProps} from "../SRinterfaces";
import {JSX, useMemo} from "react";
import {ContentFormatter} from "../utils";
import {SRCardBack, SRCardFront} from "./protocard";

export function Command({ side, card }: CardProps) {


    const imgURIs = useMemo(() => {

        return card.images.filter((img) => img.trim()).map(img => `/images/cards/${img}`)
    }, [card])

    return (
        <div className="command card">
            {side && card && <>
                {SRCardFront(card, side, [side])}
                {SRCardBack(card, side, [side])}
            </>}
            <div className="card card-image">
                {imgURIs && imgURIs.map((img) => <img src={img} />)}
            </div>
        </div>
    )
}
