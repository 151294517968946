
import {JSX, useEffect, useMemo} from "react";
import {CardProps} from "../SRinterfaces";
import {ContentFormatter} from "../utils";
import {SRCardBack, SRCardFront} from "./protocard";



function Cloze({card, side}: CardProps) {



    const imgURIs = useMemo(() => {

        return card.images.filter((img) => img.trim()).map(img => <img src={`/images/cards/${img}`} />)
    }, [card])

    return (
        <div className="cloze card">

            {side && card && <>
                {SRCardFront(card, side, [side])}
                {SRCardBack(card, side, [side])}
            </>}
            <div className="card card-image">
                {imgURIs && imgURIs}
            </div>
        </div>
    )
}


export {Cloze};