import "./calendar.css";

interface CalendarConfig {
    mobile: boolean;
}


export function Calendar({ mobile }: CalendarConfig) {
    return (
        <div className={mobile ? 'mobile-calendar' : 'calendar' + 'mobile-block'}>

        </div>
    )
}