import "./add_task_window.css";
import {useEffect, useState} from "react";


interface AddTaskWindowProps {
    closeTaskWindow: Function;
}

export function AddTaskWindow({ closeTaskWindow }: AddTaskWindowProps) {
    const [title, setTitle] = useState<string>("Task");
    const [dueDate, setDueDate] = useState<string>( new Date().toISOString().split('T')[0]);
    const [referenceID, setReferenceID] = useState<number>(-99);
    const [category, setCategory] = useState<string>("productivity");
    const [description, setDescription] = useState<string>("This is a task description.");
    const [subtasks, setSubtasks] = useState<string[]>([]);


    useEffect(() => {
        console.log(subtasks)
    },[subtasks])

    function addNewSubtask(e: any, subtaskNo: number) {
        const parentsChildren = e.target ? [...e.target.parentNode.childNodes] : [];
        if (e.key === "Enter") {
            setSubtasks([...subtasks, `subtask${subtasks.length+1}`]);
        } else if (e.key === "ArrowDown") {
            console.log(parentsChildren.length)
            console.log(subtaskNo+1)
            if (parentsChildren.length >= subtaskNo + 1) {
                console.log(parentsChildren[subtaskNo])
                parentsChildren[subtaskNo].focus();
                parentsChildren[subtaskNo].select();
            }
        } else if (e.key === "ArrowUp" && subtaskNo-2 !== 0) {
            parentsChildren[subtaskNo-2].focus();
            parentsChildren[subtaskNo-2].select();
        }
        console.log(e.key)
    }

    function updateSubtasks(value: string, i:number) {
        let subtasksCopy = subtasks;
        subtasksCopy[i] = value;
        setSubtasks([...subtasksCopy]);
    }

    return (
        <div className="add-task-bg" onClick={(e) => closeTaskWindow(e)}>
            <div className="add-task-window">
                <div className="task-title task-component">
                    {title &&
                        <input
                            className="task-input"
                            defaultValue={title}
                            onChange={(e) => setTitle(e.target.value)}
                        />}
                </div>
                <div className="task-component">
                <label>description</label>
                {description &&
                    <input
                        className="task-input"
                        defaultValue={description}
                        type={"text"}
                        onChange={(e) => setDescription(e.target.value)}
                    />}
            </div>
                <div className="task-component">
                        <label>due date</label>
                    {dueDate &&
                        <input
                            className="task-input"
                            defaultValue={dueDate}
                            type={"date"}
                            onChange={(e) => setDueDate(e.target.value)}
                        />}
                </div>
                <div className="task-component">
                    <label>reference</label>
                    {referenceID &&
                        <input
                            className="task-input"
                            defaultValue={referenceID}
                            type={"text"}
                            onChange={(e) => setReferenceID(parseInt(e.target.value))}
                        />}
                </div>
                <div className="task-component">
                    <label>category</label>
                    {category &&
                        <input
                            className="task-input"
                            defaultValue={category}
                            type={"text"}
                            onChange={(e) => setCategory(e.target.value)}
                        />}
                </div>
                <div className="task-component subtasks">
                    <label>subtasks</label>
                    {subtasks && subtasks.length > 0 &&
                        subtasks.map((subtask: string, i: number) => <div className="subtask" style={{gridRow:i+1, gridColumn: 2}}>
                            <div className="task-dot-add"></div>
                                <textarea
                                    className="task-input"
                                    defaultValue={subtask}
                                    key={i}
                                    onChange={(e) => updateSubtasks(e.target.value, i)}
                                    onKeyDown={(e) => addNewSubtask(e, i+2)}
                                />
                            </div>)}
                    {subtasks &&
                        <div className="add-subtask">
                            <button onClick={(e) => addNewSubtask({key: "Enter"}, -1)}>+</button>
                        </div>}
                </div>
                <div className="task-component btn">
                    <button>add task</button>
                </div>
            </div>
        </div>
    )
}