import { TaskObject } from "../task_view";
import { Task } from "../task/task";
import "./task_time_unit.css";
import { DateObject } from "../../planner";

interface TaskTimeUnitProps {
    granularity: string;
    date: DateObject;
    unitName: string;
    tasks: TaskObject[];
    timeGranularity: string;
    index: number;
}

export function TaskTimeUnit({ unitName, granularity, date, tasks, timeGranularity, index=0 }: TaskTimeUnitProps) {


    return  (
        <div 
            className={`task-time-unit task-${timeGranularity}`} 
            style={{gridColumn: `${timeGranularity === "week" ? index + 1: ((index%5+1)).toString()}`, gridRow:1}}
        >
                {tasks && tasks.length > 0 && tasks.map((task: TaskObject) =>  <Task granularity={timeGranularity} task_id={task.task_id} title={task.title} category={task.category} />)}
        </div>
    )
}