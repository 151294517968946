import {rawCard, ReferenceInterface} from "./types";


export class Reference implements ReferenceInterface {

    id: number;
    authors: string[] = [];
    title: string|null;
    type: string|null;
    url: string|null;

    constructor( id: number, card: rawCard ) {
        this.id = id;
        this.authors = card.authors ? card.authors.split(";") : [];
        this.type = card.type;
        this.title = card.title;
        this.url = card.url;
    }
}