import "./search_interface.css";
import {useEffect, useState} from "react";

interface FilterConfig {
    filter: string;
}

interface OptionsMap {
    domain: string[];
    field: string[];
    object: string[];
    [key: string]: OptionsMap[keyof OptionsMap];
}

const OPTIONS_MAP: OptionsMap = {
    domain: [
        "science", "social science", "arts & humanities"
    ],
    field: ["history", "mathematics"],
    object: ["Event", "ProgrammingLanguage", "ArtisticMovement", "Concept", "Theory"]
}





function Filter({filter}: FilterConfig) {
    const [options, setOptions] = useState(false);
    const [option, setOption] = useState<string>();
    // useEffect(() => {
    //
    // },[key])

    return (
        <div tabIndex={0} className="filter">
            <div className="filter-container" onClick={(e) => setOptions(true)} onMouseLeave={(e) => setOptions(false)}>
                <div className="options-container">
                    {filter && options && OPTIONS_MAP[filter].sort().map((option: string) => <div className={"option"} onClick={(e) => setOption(option)}>{option}</div>)}
                </div>
                <div className="filter-cat">
                <div className={"filter-blob" + ` ${filter}`}></div>
                <span>{filter && filter}: {option && option}</span>
                </div>
            </div>
        </div>
    )
}


export function SearchInterface() {
    const FILTERS = ["domain", "field", "object"];
    const [smartSearch, setSmartSearch] = useState(false);


    return (
        <div className="SearchInterface">
            <div className="filter-ui-container">
            <div className="filterbar">
                {FILTERS.map((filter: string) => <Filter filter={filter} />)}
            </div>
            <div className="searchbar">
                <input onChange={(e) => setSmartSearch(true)} onBlur={(e) => setSmartSearch(false)} />
                {smartSearch &&
                <div className="smart-search">
                    blablabla
                </div>}
            </div>
            </div>
        </div>
    )
}