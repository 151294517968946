
async function getAllTasks(): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/task/all`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
        }).then((response: any) => response.json())
        .then((data) => data.result);
}

async function getAllEvents(): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/event/all`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
            // @ts-ignore
        }).then((response: any) => response.json())
        .then((data) => data.result);
}

export {
    getAllEvents,
    getAllTasks
}