import {ForceGraph2D, ForceGraph3D, ForceGraphAR, ForceGraphVR} from "react-force-graph";
import {useEffect, useCallback, useRef, useState} from "react";
import {fetchTree} from "../api/api";
import {TreeObject} from "./types";
import * as d3 from 'd3';
import "./knowledgeGraph.css";
import {DefaultLinkObject} from "d3";

const COLORS = {
    "file": ["#0089a1", "#0cfff5"],
    "folder": ["#ffffff", "#ffffff"],
    "tag": ["#5b25fd", "#987eff"],
    "card": ["#ffcf27", "#e3d62c"],
    "reference": ["#ff8bd1", "#ff5a5a"]
}

export function KnowledgeGraph() {
    const [hoverNode, setHoverNode] = useState<any>(null);
    const [searchTerm, setSearchTerm] = useState("");
    const [focusNode, setFocusNode] = useState<any>();
    const graphRef = useRef();
    const [graphData, setGraphData] = useState<TreeObject>({
        nodes: [
            {id: 1, label: "Test 1", group: "SCI", type: "test"},
            {id: 2, label: "Test 2", group: "SCI", type: "test"},
            {id: 3, label: "Test 4", group: "SSC", type: "test"},
        ],
        links: [
            {
                source: 2, target: 3
            },
            {
                source: 1, target: 2
            },
            {
                source: 1, target: 3
            }
        ]
    });

    useEffect(() => {
        console.log(hoverNode)
    },[hoverNode]);



    useEffect(() => {
        async function loadGraphData( ) {
            const data = await fetchTree().then((data) => {
                // @ts-ignore
                data.nodes = data.nodes.map((node: any) => ({...node, color: COLORS[node.group], size: 600}));
                return data;
            });
            console.log(data)
            setGraphData(data);
        }

        loadGraphData();

    },[]);



    const config = {
        nodeHighlightBehavior: true,
        directed: true,
        automaticRearrangeAfterDropNode: false,
        panAndZoom: true, // Enable zooming and panning
        node: {
            size: 300,
            fontSize: 12,
            highlightStrokeColor: 'blue'
        },
        link: {
            highlightColor: 'lightblue'
        }
    };


    const zoomToNode = (nodeId: number) => {
        const node = graphData.nodes.find(n => n.id === nodeId);
        if (node) {
            setFocusNode(node);
            // @ts-ignore
            graphRef.current.centerAt(node.x, node.y, 1000); // Center at node's coordinates
            // @ts-ignore
            graphRef.current.zoom(4, 100); // Zoom in (scale factor = 8, duration = 1000ms)
        }
    };

    const focusOnNode = (e: any) => {
        if (e.key === "Enter" && searchTerm.length > 0) {
            for (let node of graphData.nodes) {
                if (node.label && node.label.toLowerCase().includes(searchTerm.toLowerCase())) {
                    zoomToNode(node.id);
                    d3.forceManyBody().strength(-300);
                    d3.forceCollide().radius(50);
                    break;
                }
            }
        }
    }

    const calculateLinkColor = (link: any) => {
        if (link.target.color) return link.target.color[0];
        return '#6b6b6b';
    }

    useEffect(() => {
        const centralNodeId = 1;  // ID of the central node (you can modify this)
        const centralNode = graphData.nodes.find(node => node.id === centralNodeId);

        if (centralNode) {
            // Find the nodes connected to the central node (i.e., its neighbors)
            const connectedNodes = graphData.links
                .filter(link => link.source === centralNodeId || link.target === centralNodeId)
                .map(link => (link.source === centralNodeId ? link.target : link.source))
                .map(id => graphData.nodes.find(node => node.id === id));

            // Set up a circular layout around the central node
            const radius = 150;  // Distance from the central node
            const angleStep = (2 * Math.PI) / connectedNodes.length;  // Angle between each node

            connectedNodes.forEach((node, index) => {
                const angle = index * angleStep;
                // @ts-ignore
                node.x = centralNode.x + radius * Math.cos(angle);
                // @ts-ignore
                node.y = centralNode.y + radius * Math.sin(angle);
            });

            // Update the graph positions
            // @ts-ignore
            if (graphRef.current) graphRef.current.d3ReheatSimulation();  // Reheat the force simulation to apply new positions
        }
    }, [graphData]);


    // @ts-ignore
    return (
        <div className="graph-container">
            <ForceGraph2D
                ref={graphRef}
                graphData={graphData}
                linkDirectionalParticles={2}
                linkWidth={"1em"}
                enableNodeDrag={true}
                enablePointerInteraction={true}
                linkColor={(link) => calculateLinkColor(link)}
                nodeCanvasObject={(node, ctx) => {
                    if (node && node.x && node.y && node.color) {
                        // Draw the node (you can customize this as well)
                        const radius = 10;  // Set the radius of the node
                        // @ts-ignore
                        const gradient = ctx.createRadialGradient(node.x, node.y, 0, node.x, node.y, radius);

                        // Define gradient colors (from center to edge)
                        gradient.addColorStop(0, node.color[0]);  // Center color
                        gradient.addColorStop(1, node.color[1]);  // Edge color

                        // Apply the gradient to fill the node
                        ctx.fillStyle = gradient;
                        ctx.beginPath();
                        // @ts-ignore
                        ctx.arc(node.x, node.y, 5, 0, 2 * Math.PI, false);
                        ctx.fill();

                        if (node.group !== "card") {
                            // Draw the label for each node
                            const label = node.label || node.id;
                            ctx.font = '14px Arial';
                            ctx.fillStyle = "white";
                            ctx.textAlign = 'center';
                            // @ts-ignore
                            ctx.fillText(label, node.x, node.y - 10);  // Adjust label position slightly above the node
                        }

                    }



                }}
            />
            <div className="search-graph-container">
                <input onChange={(e) => setSearchTerm(e.target.value)} onKeyDown={(e) => focusOnNode(e)} />
                <div className="legend-container">
                    {Object.entries(COLORS).map(([group, colors], i: number) => {
                        const rowLeft = Math.round(i/2) + 1;
                        const rowRight = rowLeft + 1;
                        return (
                            <div className="legend-block" style={{gridRow: `${rowLeft}/${rowRight}`, gridColumn: i%2==0? "1/2" : "2/3"}}>
                                <div className="legend-dot" style={{background: `linear-gradient(45deg, ${colors[0]}, ${colors[1]})`}}></div>
                                <div className="legend-label" style={{color: colors[1]}}>{group}</div>
                            </div>
                        )
                    })}
                </div>
            </div>

        </div>

    )
}