import {CardProps} from "../SRinterfaces";
import {useEffect, useMemo} from "react";
import { ContentFormatter } from "../utils";
import {SRCardFront} from "./protocard";

export function Visualization({ card, side }: CardProps) {

    return (
        <div className="card visualization">
            {card && side &&
                <>


                        {SRCardFront(card, side, [side])}




                        {card.reviewType === "front" && <span className={side === "front" ? "img-masked" : ""}><img  className="vis-img" src={`/images/cards/${card.back.text}`} /></span>}
                        {card.reviewType === "back" && <span className=""><img className="vis-img" src={`/images/cards/${card.back.text}`} /></span>}

                </>}
        </div>
    )


}