import { Reference } from "./TrackingSystem";
import { useState } from "react";
import {activateRef} from "../api/reference_tracking";

interface AddReferenceWindowProps {
    reference: Reference;
    closeWindow: Function
}

export function AddReferenceWindow({reference, closeWindow}: AddReferenceWindowProps) {
    const [totalUnits, setTotalUnits] = useState<number>(0);
    const [unit, setUnit] = useState<string>("");

    async function activateReference() {
        let referenceCopy = reference;
        referenceCopy["unit"] = unit;
        referenceCopy["total_units"] = totalUnits;
        if (totalUnits !== 0) {
            if (unit.length >= 1) {
                await activateRef(referenceCopy).then((status: number) => status === 200 ? closeWindow() : alert("Failed to activate reference."));
            } else {
                alert("Unit is not specified.")
            }
        } else {
            alert("Total units cannot be 0.")
        }
    }

    return (
        <div className="add-tracking-ref-bg">
        <div className="add-tracking-ref-window">
            <h2>{reference.title}</h2><div><button className="add-tracking-ref-window-close" onClick={() => closeWindow()}>x</button></div>
            <div className="add-tracking-ref-window-sub">
                <div className="add-ref-label"><label>type</label></div>
                <div className="add-ref-value"><i>{reference.type}</i></div>
                 </div>
            <div className="add-tracking-ref-window-sub">
                <div className="add-ref-label"><label>units</label></div>
                <div className="add-ref-value"> <input onChange={(e) => setUnit(e.target.value)} className="add-ref-input" /></div>

            </div>
            <div className="add-tracking-ref-window-sub">
                <div className="add-ref-label"><label>total number of units</label></div>
                <div className="add-ref-value"><input onChange={(e) => setTotalUnits(parseInt(e.target.value))} className="add-ref-input" /></div>
            </div>
            <div className="add-tracking-ref-window-sub">
                <button onClick={() => activateReference()}>Start tracking</button>
            </div>
        </div>
        </div>
    )
}