import {CardProps} from "../SRinterfaces";
import { ContentFormatter } from "../utils";
import {SRCardBack, SRCardFront} from "./protocard";
import {useEffect, useMemo} from "react";
import {tab} from "@testing-library/user-event/dist/tab";


function transformCode(codeString: string) {
    const lines = codeString.split("\n");
    let container = [];
    let toPush;
    for (let i = 0; i < lines.length; i++) {
        let tabCount = 0;
        lines[i] = lines[i].replace(/\t/g, "TAB")
        let index = lines[i].indexOf("TAB");
        let absoluteIndex = 0;
        while (index != -1 && absoluteIndex < lines.length) {
            tabCount += 1;
            absoluteIndex = tabCount * 3;
            index = lines[i].slice(absoluteIndex).indexOf("TAB");
            console.log(lines[i])
        }
        if (tabCount > 0) {
            console.log(i, "TABCOUNT: ", tabCount);
            // @ts-ignore
            let str = [...Array(tabCount).keys()].map((key) => <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>)
            toPush = <div>{str.map((key) => key)}{lines[i].replace(/TAB/g, '')}</div>
        } else {
            toPush = <div>{lines[i]}</div>
        }

        // @ts-ignore
        container.push(toPush)
    }
    return container;
}

export function Code({ card, side }: CardProps) {
    const imgURIs = useMemo(() => {

        return card.images.filter((img) => img.trim()).map(img => <img src={`/images/cards/${img}`} />)
    }, [card])

    useEffect(() => {
        console.log(side)
    },[])

    return (
        <div className="code card">
            {card && side &&
            <>
                {side && card && <>
                    {SRCardFront(card, side, [side])}
                    {card.reviewType === "front" && side === "front" ? <code><span className="placeholder"></span></code> :
                        <div className="code-div">{transformCode(card.back.text).map((div) => div)}</div> }
                </>}
                <div className="card card-image">
                    {imgURIs && imgURIs}
                </div>
            </>}
        </div>
    )
}