

export class Code {

    rawText: string;

    constructor(text: string) {
        this.rawText = text;
    }

    CodeBlock = () => {
        return (
            <div className="spacrep-code-block">
                {this.rawText.split("\n").map((line: string) => <this.CodeLine line={line} />)}
            </div>
        )
    }

    CodeUnit = ({ unit }: any) => {
        if (unit === "\t") return <>    </>
        return unit;
    }

    CodeLine = ({ line }: any) => {
        return (
            <span className="spacrep-code-line">
                {line.split("").map((unit: string) => <this.CodeUnit unit={unit} />)}
                <br />
            </span>
        )
    }

    toHMTL() {
        return <this.CodeBlock />
    }

}