

interface ObjectDisplayProps {
    objectType: string;
}

export function ObjectDisplay({objectType}: ObjectDisplayProps) {


    return (
        <div className="object-db-display">
            <div className="display-sort">

            </div>
            {objectType && objectType.replace("_", " ")}
        </div>
    )
}