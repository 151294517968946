import {useEffect, useState} from "react";


export function EvaluationBar( { activeCard, processScore, side, switchSide }: any ) {
    const [scoreRange, setScoreRange] = useState<number[]>([]);

    useEffect(() => {
        // @ts-ignore
        setScoreRange([...new Array(activeCard.maxScore + 1).keys()]);

        document.addEventListener('keydown', processKeyDown);

        return () => {
            document.removeEventListener('keydown', processKeyDown);
        }
    },[activeCard])

    const processKeyDown = ( e: any ) => {
        if (scoreRange.indexOf(parseInt(e.key)) !== -1) {
            processScore(parseInt(e.key));
        }
    }

    return (
        <div className="spacrep-eval-bar">
            {side === "front" && <button onClick={() => switchSide()}>Show answer</button>}
            {side === "back" && scoreRange.map((_, i) => <button onClick={() => processScore(i)}>{i}</button>)}
        </div>
    )
}