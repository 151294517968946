import { Event } from "./event/event";
import { useEffect, useState } from "react";
import { EventObject } from "./event/event";
import "./agenda_time_unit.css";

interface DayProps {
    granularity: string;
    date: string;
    weekday: number;
    day: string;
    openPlanEventWindow: Function;
    events: EventObject[];
    index: number;
}

export function AgendaTimeUnit({granularity, weekday, day, date, openPlanEventWindow, events, index}: DayProps) {

    useEffect(() => {
        if (granularity === "week") {
            console.log("Generating hours..")
            generateHours();
        }
    },[])

    useEffect(() => {
        if (granularity === "week") {
            console.log("Generating hours..")
            generateHours();
        }
    },[granularity])
 
    function updateEvent() {
        
    }

    function createEvent(e: any) {
        console.log("Creating new event...")
        console.log(e)
        openPlanEventWindow(e);
    }

    function generateHours() {
        const timeline = document.getElementById("timeline-container");
        const weekdays = document.getElementsByClassName("week-day");
        if (timeline && !timeline?.hasChildNodes() && granularity === "week") {
            for (let hour = 0; hour<24;hour++) {
                const block = document.createElement('div');
                block.classList.add('hour-block');
                block.style.height = "60px";
                block.style.boxSizing = "border-box";
                block.textContent = `${String(hour).padStart(2, '0')}:00`;
                timeline?.appendChild(block);
                for (let day=0; day<7; day++) {
                    const block = document.createElement('div');
                    block.classList.add('hour-block');
                    block.style.height = "60px";
                    block.style.boxSizing = "border-box";
                    block.tabIndex = 0;
                    block.addEventListener('dblclick', createEvent);
                    weekdays[day].appendChild(block);
                }
            }
        }
    }

    return (
        <>
        {granularity === "week" && <div className={`week-day ${day}`} id={day}>
            {events && events.length > 0 && events.map((e: EventObject, i: number) => <Event 
                key={i}
                granularity={granularity} 
                day={day} 
                onUpdate={updateEvent} 
                openPlanEventWindow={openPlanEventWindow} 
                eventData={e}
            />)}
        </div>}
        {granularity === "month" && 
            <div className="month-day" style={{gridColumn: index%5}} id={day} >
                {events && events.length > 0 && events.map((e: EventObject, i: number) => <Event 
                key={i}
                granularity={granularity} 
                day={day} 
                onUpdate={updateEvent} 
                openPlanEventWindow={openPlanEventWindow} 
                eventData={e}
            />)}
            </div>}
        </>
    )
}