import "./reading_list.css";
import {useState} from "react";
import {addReadingListItem} from "../api/api";
import * as react from "react";
import {GenericForm} from "../generic/form";

export function ReadingListInterface () {
    const [title, setTitle] = useState<string>("");
    const [authors, setAuthors] = useState<string>();
    const [referenceType, setReferenceType] = useState<string>();
    const [tags, setTags] = useState<string>();
    const [notes, setNotes] = useState<string>();
    const [key, setKey] = useState(0);
    const PROPERTIES = [{
        key: "title",
        type: "text",
        options: []
    }, {
        key : "authors",
        type: "text",
        options: []
    }, {
        key: "notes",
        type: "text",
        options: []
    } , {
        key: "tags",
        type: "text",
        options: []
    },
        {
        key:"reference type",
        type: "dropdown",
        options: ["article", "book", "research paper", "git repository", "documentation", "website"]
    }];


    function setProperty(property: string, value: string) {
        if (property === "title") {
            setTitle(value);
        } else if (property === "authors") {
            setAuthors(value);
        } else if (property === "notes") {
            setNotes(value);
        } else if (property === "tags") {
            setTags(value);
        } else if (property === "reference type") {
            setReferenceType(value);
        }
    }

    function clearAll() {
        setTags("");
        setTitle("");
        setReferenceType("");
        setAuthors("");
        setKey(key+1);
    }

    async function processItem(){
        if (title && referenceType) {
            await addReadingListItem(title,  referenceType, authors, notes, tags).catch(err => alert(`Failed to add reference:\n${err}`))
                // @ts-ignore
                .then((status: number) => status === 200 ? clearAll() : console.log("An error occurred"))
        }
    }

    return (
        <div className="reading-list-ui">
            <div className="reading-list-display">

            </div>
        <GenericForm key={key} processForm={processItem} properties={PROPERTIES} setProperty={setProperty} title={title} />
        </div>
    )
}

