

import React, {useContext, useEffect, useState} from 'react';
import "./login_page.css";
import {AuthContext, AuthProvider} from "./auth_context";
import {useNavigate} from "react-router-dom";
import {useWindowSize} from "../generic/window_resize";
import {login} from "../api/api";




const LoginPage = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [width, height] = useWindowSize();
    // @ts-ignore
    const {setIsLoggedIn} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        bounce();
    },[]);


    // @ts-ignore

    async function handleSubmit (e) {
        e.preventDefault();
        if (username.length > 0 && password .length > 0) {
            await login({username: username, password: password}).then(function (status) {
                if (status === 200) {
                    setIsLoggedIn(true);
                    return navigate("/")
                } else {
                    alert("Login failed");
                }
            })
        }
    };

    function bounce() {
        var bubble = document.getElementById("bubble");
        if (bubble) {
            var x = 0, y = 0;
            var xSpeed = 8, ySpeed = 12;
            animate();
        }


        function animate() {

            // @ts-ignore
            var maxX = width - bubble.offsetWidth;
            // @ts-ignore
            var maxY = height - bubble.clientHeight;

            x += xSpeed;
            y += ySpeed;

            if (x <= 0 || x>= maxX) xSpeed *= -1;
            if (y <= 0 || y>= maxY) ySpeed *= -1;

            if (bubble) {
                bubble.style.left = x + 'px';
                bubble.style.top = y + 'px';
            }


            requestAnimationFrame(animate)
        }



    }

    return (
        <div className='ui-login'>
            <div id={"bubble"}></div>
        <div className='ui-main'>
        <div className="login">
            <input 
                type="text" 
                value={username} 
                onChange={(e) => setUsername(e.target.value)} 
                placeholder="Username" 
            />
            <input 
                type="password" 
                value={password} 
                onChange={(e) => setPassword(e.target.value)} 
                placeholder="Password" 
            /></div>
            <button onClick={(e) => handleSubmit(e)}>login</button>

        </div>
        </div>
    );
};

export default LoginPage;
