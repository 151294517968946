import {useState, useEffect, JSX, useMemo} from "react";
import {CardProps} from "../SRinterfaces";
import {ContentFormatter} from "../utils";
import "../css/translation.css";
import {SRCardBack, SRCardFront} from "./protocard";

function Translation({card, side}: CardProps) {


    const count = useMemo(() => {
        let count = -99;
        if (card.reviewType === "front") {
            if (card.back.type === "list" || card.back.text.includes(";")) {
                count = card.back.text.split(";").length;
            }
        }
        return count;
    },[card])

    const imgURIs = useMemo(() => {

        return card.images.filter((img) => img.trim()).map(img => <img src={`/images/cards/${img}`} />)
    }, [card])


    return (
        <>
            {side && card && count &&
        <div className="">
            {<>
                {SRCardFront(card, side, [side], count)}
                {SRCardBack(card, side, [side])}
            </>
            }
            <div className="card card-image">
                {imgURIs && imgURIs}
            </div>
        </div>}

        </>
    )
}


export {Translation}