import "./tracking_system.css";
import {TrackingUnit} from "./TrackingUnit";
import {useEffect, useState} from "react";
import { AddReferenceWindow } from "./AddReferenceWindow";
import {activateRef, getActiveReferences, getInactiveReferences} from "../api/reference_tracking";
import AsyncSelect from "react-select/async";
import {Workspace} from "./workspace";

export interface ReferenceDB {
    [key: string]: any;
    reference_id: number;
    title: string;
    authors: string;
    type: string;
    platform: string;
    unit: string;
    total_units: number;
    units_read: string;
    units_summarized: string;
    units_keyword: string;
    units_feynman: string;
    units_mindmap: string;
    units_exercise: string;
    units_spaced_repetition: string;
}

export interface Reference {
    [key: string]: any;
    reference_id: number;
    title: string;
    authors: string[];
    type: string;
    platform: string;
    unit?: string;
    total_units?: number;
    units_summarized?: string[];
    units_read?: string[];
    units_mindmap?: string[];
    units_exercise?: string[];
    units_feynman?: string[];
    units_spaced_repetition?: string[];
    units_keyword?: string[];
}

const createReference = () => ({
    reference_id: -99,
    title: "",
    authors: [],
    type: "",
    platform: "",
    unit: "",
    total_units: -99,
    units_summarized: [],
    units_read: [],
    units_exercise: [],
    units_feynman: [],
    units_spaced_repetition: [],
    units_keyword: [],
    units_mindmap: []
})

function sortTitles(a: Reference, b:Reference) {
    if (a.title < b.title) {
        return -1;
    } else if (a.title > b.title) {
        return 1;
    } else {
        return 0;
    }
}

export function TrackingSystem() {
    const [inactiveReferences,setInactiveReferences] = useState<Array<Reference>>([]);
    const [activeReferences, setActiveReferences] = useState<Array<Reference>>([]);
    const [addingRef, setAddingRef] = useState(false);
    const [refToAdd, setRefToAdd] = useState<Reference>();
    const [detailedView, setDetailedView] = useState<Reference|null>(null);

    useEffect(() => {
        initReferences();
    },[])

    async function initReferences () {
        const refs = await getInactiveReferences();
        const activeRefs = await getActiveReferences();
        refs.sort(sortTitles);
        activeRefs.sort(sortTitles);
        if (activeRefs && activeRefs.length > 0) {
            setActiveReferences([...processRefs(activeRefs)]);
        }
        if (refs && refs.length > 0) {
            setInactiveReferences([...processRefs(refs)]);
        }
    }

    function processRefs(refs: ReferenceDB[]):Reference[] {
        let refsCopy: Reference[] = [];
        let newRef: Reference;
        const KEYS = ['authors', 'units_read', 'units_summarized', 'units_keyword', 'units_mindmap', 'units_exercise', 'units_feynman', 'units_spaced_repetition'];
        for (let ref of refs) {
            newRef = createReference();
            for (let key in ref) {
                if (KEYS.includes(key)) {
                    newRef[key] = ref[key] !== null ? ref[key].split(";").filter((item: string) => item !== "") : "";
                } else {
                    newRef[key] = ref[key] === null ? "" : ref[key];
                }
            }
            refsCopy.push(newRef);
        }
        console.log(refsCopy)
        return refsCopy;
    }

    async function activateReference(reference: Reference) {
        console.log(reference)
        if (reference.unit === "") {
            setAddingRef(true);
            setRefToAdd(reference);
        } else {
            await activateRef(reference).then((status: number) => status === 200 ? closeWindow() : alert("Failed to activate reference."));
        }
    }

    function closeWindow() {
        setAddingRef(false);
        initReferences();
    }

    function closeDetailedView() {
        setDetailedView(null);
    }

    function openDetailedView(reference: Reference) {
        setDetailedView(reference);
    }

    // @ts-ignore
    return (
        <div className="tracking-system-container">
            <div className="tracking-system-ui">
                {detailedView !== null && <Workspace reference={detailedView} closeWindow={closeDetailedView} />}
                {addingRef && refToAdd && <AddReferenceWindow reference={refToAdd} closeWindow={closeWindow}/>}
            <div className="tracking-add-ref">
                {inactiveReferences && inactiveReferences.length > 0 &&
                    <select
                        className="inactive-select"
                        style={{maxHeight:"30em"}}
                    >
                        {inactiveReferences.map((ref) => <option onClick={(e) => activateReference(ref)}>{ref.title}</option>)}
                    <div className="tracking-filter-active-ref">
                        <label></label><input type="checkbox"/>
                    </div>

                </select>}
            </div>
            <div className="active-ref-container">
                {activeReferences && activeReferences.map((ref, number) =>
                    <TrackingUnit
                        index={number}
                        reference={ref}
                        reload={initReferences}
                        type={"deactivate"}
                        openDetailedView={openDetailedView}
                    />)
                }
            </div>
            </div>
        </div>
    )
}