import {SRCard} from "./interface";

interface MetaBarProps {
    activeCard: SRCard|null|undefined;
    reviewCount: number;
}

export function MetaBar({ activeCard, reviewCount }: MetaBarProps) {

    return (
        <>
            {activeCard &&
                <>
                    <div className="card-meta">
                        <span className={"data-label"}>cardID: </span>{activeCard.cardID}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={"data-label"}>card type: </span>{activeCard.cardType}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <span className={"data-label"}>review count: </span>{reviewCount}</div>
            <div className="ref-bar">
                 <>
                    <p className="ref" tabIndex={0}>
                        {activeCard.referenceTitle ? (<div><i>{activeCard.referenceTitle}</i>. {activeCard.referenceAuthors.length > 0 && !activeCard.referenceAuthors.join(";").includes('NA') ? activeCard.referenceAuthors.join(", ") : "N/A"}</div>) : "Unknown source." }
                    </p></>
            </div>
                </>}
        </>

    )
}