import {useContext} from "react";
import {AuthContext} from "./auth_context";
import "./authentication_bar.css";
import {Link, useLocation} from "react-router-dom";

export function AuthenticationBar() {
    // @ts-ignore
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

    const location = useLocation();

    const logOut = (e: any) => {
        e.preventDefault();
        setIsLoggedIn(false);
    }

    return (
        <div className="auth-bar">
            {isLoggedIn && <button onClick={(e) => logOut(e)}>log out</button>}
            {!isLoggedIn && location.pathname !== "/login" && <Link to="/login">log in</Link>}
        </div>
    )
}