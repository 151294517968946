import "./day.css";

interface DayConfig {
    date: number;
    setActiveDay: Function;
}

export function CalendarDay({date, setActiveDay}: DayConfig) {
    return (
        <div className="Day" onClick={(e) => setActiveDay(date)}>
            <h1>{date && date}</h1>
        </div>
    )
}