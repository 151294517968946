import "./task.css";
import {useState} from "react";

export interface TaskObject {
    task: string;
    type: string;
    estimated_duration_min: number;
}

interface CheckboxConfig {
    type: string;
}

function Checkbox({type}: CheckboxConfig) {
    const [checked, setChecked] = useState(false);

    return (
        <div tabIndex={0} className={"task-blob" + ` ${type}`} onClick={(e) => setChecked(!checked)}>
            {checked && <span>V</span>}
        </div>
    )
}


export function Task({task, type, estimated_duration_min}: TaskObject) {


    return (
        <div className="Task">
            <div className="task-container">
                <Checkbox type={type} />
                <span>{task}</span>
            </div>
        </div>
    )
}