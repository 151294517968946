import "./object_database.css";
import {Link, useParams} from "react-router-dom";
import {useEffect} from "react";
import {ObjectDisplay} from "./object_display";

export function Object_database_ui() {
    let { object } = useParams();

    useEffect(() => {
        if (object) {

        }
    },[])

    return (
        <div className="object-db-ui">
            <div className="object-db-ui-menu">
                <Link className="menu-button odb" to={"/object_database/algorithms"}>Algorithms</Link>
                <Link className="menu-button odb" to={"/object_database/physical_objects"}>Physical Objects</Link>
            </div>
            {object && <ObjectDisplay objectType={object} />}
        </div>
    )
}