import { useEffect, useState } from "react";
import { DateHeader } from "../date_header";
import "./task_view.css";
import { DateObject, WEEKDAYS } from "../planner";
import { TaskTimeUnit } from "./time_unit/task_time_unit";
import { getAllTasks } from "../../api/planner";
import {AddTaskWindow} from "./time_unit/add_task_window";

interface TaskViewProps {
    granularity: string;
    updateDates: Function;
    dates: DateObject[]
    setUnitFromCurrent: Function;
    unitFromCurrent: number;
}

export interface TaskObject {
    task_id: number;
    title: string;
    category: string;
    track: string|null;
    estimated_duration: number;
    actual_duration: number|null;
    due_date: string;
}

const DUMMY_TASKS = [
    {
        task_id: 1,
        title: "Read ch1 of 531",
        category: "productivity",
        track: "career",
        estimated_duration: 40,
        actual_duration: 60,
        due_date: "2024-03-06"
    },
    {
        task_id: 3,
        title: "Clean bathroom",
        category: "household",
        track: null,
        estimated_duration: 20,
        actual_duration: 15,
        due_date: "2024-03-10"
    },
    {
        task_id: 5,
        title: "Pay taxes",
        category: "finance",
        track: null,
        estimated_duration: 5,
        actual_duration: null,
        due_date: "2024-03-09"
    }
]

export function TaskView( { granularity, updateDates, dates, setUnitFromCurrent, unitFromCurrent }:TaskViewProps ) {
    const [units, setUnits] = useState<DateObject[]>(dates);
    const [taskGranularity, setTaskGranularity] = useState<string>("day");
    const [tasks, setTasks] = useState<TaskObject[]>(DUMMY_TASKS);
    const [addTaskWindow, setAddTaskWindow] = useState(false);

    useEffect(() => {
        setUnits([...dates])
        // generateComponents();
    },[dates])

    useEffect(() => {
        loadTasks();
    },[])

    async function loadTasks() {
        const tasks = await getAllTasks();
        console.log(tasks)
        if (tasks) {
            setTasks([...tasks]);
        }
    }

    function openTaskWindow() {
        setAddTaskWindow(true);
    }

    function closeTaskWindow(e: any) {
        if (e.target.className.includes('add-task-bg')) {
            setAddTaskWindow(false);
        }
    }

    return (
        <div className="task-display" id="task-display">
            {addTaskWindow && <AddTaskWindow closeTaskWindow={closeTaskWindow}/>}
            <DateHeader 
                granularity={granularity}
                updateDates={updateDates}
                dates={dates}
                setUnitFromCurrent={setUnitFromCurrent}
                unitFromCurrent={unitFromCurrent}
                addTask={openTaskWindow}
            />
            {granularity && <div className={`tasks-${granularity}`} id="tasks">
                {units && taskGranularity && granularity && units.length > 0 && 
                units.map((unit: DateObject, index: number) => 
                    <TaskTimeUnit 
                        key={index}
                        index={index}
                        unitName={unit['day']} 
                        granularity={taskGranularity} 
                        date={dates[index]}
                        tasks={tasks.filter((task: TaskObject) => task.due_date === unit['date'])}
                        timeGranularity={granularity}
                    />)}
            </div>}
        </div>
    )
}