import 'flatpickr/dist/flatpickr.min.css';
import { useEffect, useRef, useState } from 'react';
import Flatpickr from "react-flatpickr";
import "./date_picker.css";
import { DateObject } from './planner';


interface DatePickerProps {
    updateDates: Function;
    dateRange: DateObject[];
}

export function DatePicker({ dateRange, updateDates }: DatePickerProps) {
    const [ date, setDate ] = useState<Date>(new Date());
    let picker = useRef<any>();

    useEffect(() => {
        if (picker.current) {
            const inputElement = picker.current.querySelector('input');
            if (inputElement) {
                console.log(inputElement)
              inputElement.click();
            }
        }
    },[])

    const trimArray: string[] = dateRange.map(item => item['date']);

    return (
        <div ref={picker} className='flatpicker-container'>
            {date && <Flatpickr
                value={trimArray}
                onChange={(date:Date[]) => updateDates([...date])}
            />}
        </div>
    )
}