
import {useState, useEffect, useMemo, useRef} from "react";
import "./review_mode.css";
import {ProtoCard} from "./cards/protocard";
import {ReviewMenu} from "./reviewMenu";
import {SRCard} from "./interface";
import {EvaluationBar} from "./evaluationBar";
import {MetaBar} from "./metaBar";


interface MetaDataProps {
    card: SRCard;
}

function MetaData({ card }: MetaDataProps) {
    const props: string[] = ["tags"];


    const filterTag = (tag: string) => {
        tag = tag.includes(":") ? tag.split(":")[1] : tag
        let sideToCheck = card.reviewType === "front" ? card.back.text : card.front.text
        return !sideToCheck.toLowerCase().includes(tag.replace(/_/g, ' ').toLowerCase()) && !tag.includes('undefined')
    }

    return (
        <div className="metadata-bar">
            {card && card.back && card.back.text && card.tags.filter((tag: string) => filterTag(tag)).map((tag: string) => <span className="tag">{tag.replace(/_/g, ' ')}</span>)}
        </div>
    )
}


interface ReviewModeProps {
    cardsDue: SRCard[];
    refresh: Function;
    setFilter: Function;
    filter: string;
    updateCardsDue: Function;
    filterCardsByField: Function;
}

export function ReviewMode({ cardsDue, refresh, setFilter, filter, updateCardsDue, filterCardsByField }: ReviewModeProps) {
    const [activeCard, setActiveCard] = useState<SRCard|null>();
    const [side, setSide] = useState<string>("front");
    const [typeMode, setTypeMode] = useState(false);
    const [configMenuUnfold, setConfigMenuUnfold] = useState(false);
    const [activeFields, setActiveFields] = useState<string[]>([]);
    const [reviewCount, setReviewCount] = useState(0);


    useEffect(() => {
        if (cardsDue) {
            // Just opening review window - pick random first card.
            setActiveCard(pickRandomCard());
        }
    }, []);

    useEffect(() => {
        if (activeCard) {
            removeAnnotation();
        }
    }, [side, activeCard]);

    const allFields: string[] = useMemo(() => {
        // @ts-ignore
        return [...new Set(cardsDue.map((card) => card.fields).flat())];
    },[cardsDue]);

    function addField(field: string) {
        // Add a field to the active fields
        let fieldsComplete = [...activeFields, field];
        console.log(fieldsComplete)
        // @ts-ignore
        setActiveFields([...new Set(fieldsComplete)]);
    }

    function removeField(field: string) {
        // Remove a field from the active fields
        let fieldsCopy = activeFields;
        fieldsCopy = fieldsCopy.filter((activeField:string) => activeField !== field);
        // @ts-ignore
        setActiveFields([...new Set(fieldsCopy)]);
    }

    function removeAnnotation() {
        let annots = Array.from(document.getElementsByTagName("annotation"));
        for (let el of annots) {
            if (el && el.parentNode) {
                el.parentNode.removeChild(el);
            }
        }
    }

    async function updateDeck() {
        // Update of cardsDue deck after reviewing a card.
        let cardsCopy = [...cardsDue];
        if (activeCard) {
            // Filter out reviewed card.
            cardsCopy = cardsCopy.filter(item => item.cardID !== activeCard.cardID);
            setReviewCount((reviewCount) => reviewCount + 1);
        }
        console.log(cardsCopy.length, "cards remaining..");
        updateCardsDue([...cardsCopy]);
    }

    async function switchActiveCard(): Promise<void> {
        // Pick a next card, filter based on active fields.
        let activeCardTemp: SRCard | null;

        // Check if current card still exists in deck, in that case don't switch cards.
        // @ts-ignore
        const isPresent = activeCard ? filteredCards.filter((card) => card.cardID === activeCard.cardID).length > 0 : false;

        // @ts-ignore
        if (filteredCards.length > 0 && !isPresent) {
            activeCardTemp = pickRandomCard();
            console.log(activeCardTemp)
            setActiveCard(activeCardTemp);
            setSide("front");
        } else if (filteredCards.length === 0 && isPresent) {
            setActiveCard(null);
        }
    }

    const filteredCards = useMemo(() => {
        // When card deck or active fields change, recalculate matching cards
            return activeFields.length > 0 ? cardsDue.filter((card) => {
                return card.fields.filter(field => activeFields.includes(field)).length > 0
            }) : cardsDue;
        },[cardsDue, activeFields])

    function pickRandomCard(): SRCard {
        return filteredCards[Math.floor(Math.random() * filteredCards.length)];
    }

    useEffect(() => {
        if (filteredCards.length > 0) {
            switchActiveCard()
        } else {
            setActiveCard(null)
        }
    },[filteredCards])

    async function updateActiveCard(front: string, back: string) {
        // @ts-ignore
        setActiveCard({...activeCard, front: {...activeCard.front, text: front}, back: {...activeCard.back, text: back}});
    }

    // @ts-ignore
    return (
        <div className="review-mode">

            {(cardsDue.length === 0) && <div className="no-cards-left"><h1>No cards left</h1></div>}
            {(cardsDue.length > 0 && filteredCards.length === 0) && <div className="no-cards-left"><h1>No cards left for selected fields</h1></div>}

            <ReviewMenu
                activeCard={activeCard}
                refresh={refresh}
                switchActiveCard={updateDeck}
                configMenuUnfold={configMenuUnfold}
                activeFields={activeFields}
                selectField={addField}
                removeField={removeField}
                allFields={allFields}
            />

            {activeCard && <MetaBar activeCard={activeCard} reviewCount={reviewCount} />}

            {activeCard && <MetaData card={activeCard} />}

            <div className="blur"></div>

            {activeCard && side &&
                <ProtoCard refresh={updateDeck} typeMode={typeMode} side={side} card={activeCard} turnCard={() => setSide("back")} />}

            {activeCard &&
                <EvaluationBar
                    activeCard={activeCard}
                    switchActiveCard={updateDeck}
                    side={side}
                    setSide={setSide}
                />
            }

        </div>
    );
}
