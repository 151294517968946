import {Reference} from "../source_tracking_system/TrackingSystem";

async function getActiveReferences(): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/references/active`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
            // @ts-ignore
        }).then((response) => response.json())

        .then(data => data.result);
}

async function getAarchivedReferences(): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/references/archived`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
            // @ts-ignore
        }).then((response) => response.json())

        .then(data => data.result);
}

async function getInactiveReferences(): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/references/inactive`,
        {
            method: "get",
            headers: {
                "Content-Type": "application/json",
            }
            // @ts-ignore
        }).then((response) => response.json())
        .then(data => data.result);
}

async function activateRef(reference: Reference): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/references/activate`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"reference": reference})
            // @ts-ignore
        }).then((response) => response.status);
}

async function deactivateRef(reference: Reference): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/references/deactivate`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"reference": reference})
            // @ts-ignore
        }).then((response) => response.status);
}

async function addUnits(key: string, value: string[], reference: Reference, action: string): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/references/add_units`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"column": key, "value": value, "reference": reference, "action": action})
        }).then((response) => response.status);
}

async function archiveRef(reference: Reference): Promise<any> {
    return await fetch(
        `https://fusionfrontier.tech/api/references/archive`,
        {
            method: "post",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({"reference": reference})
            // @ts-ignore
        }).then((response) => response.status);
}

export {
    archiveRef,
    activateRef,
    deactivateRef,
    getAarchivedReferences,
    getActiveReferences,
    getInactiveReferences,
    addUnits
}