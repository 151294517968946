import { PiArrowLeftLight, PiArrowRightLight } from "react-icons/pi";
import { useState } from "react";
import { DatePicker } from "./date_picker";
import { DateObject } from "./planner";
import "./date_header.css";
import { PiPlusCircleThin } from "react-icons/pi";

interface DateHeaderProps {
    granularity: string;
    updateDates: Function;
    dates: DateObject[];
    setUnitFromCurrent: Function;
    unitFromCurrent: number;
    addTask?: Function;
}

export function DateHeader({ granularity, updateDates, dates, setUnitFromCurrent, unitFromCurrent, addTask }:DateHeaderProps ) {
    const [calendarPicker, setCalendarPicker] = useState(false);


    return (
        <>
            <div className="display-header-container">
                <div className="display-header">
                    <PiArrowLeftLight 
                        onClick={() => setUnitFromCurrent(unitFromCurrent - 1)}
                        className="arrow-date"
                        size={"2em"}
                        style={{cursor: "pointer"}}
                    />
                    {dates && dates.length > 0 && <div className="date-header" tabIndex={0}  onClick={() => setCalendarPicker(!calendarPicker)}><h2>&nbsp;{dates[0]['date']}&nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;{dates[dates.length-1]['date']}&nbsp;</h2></div>}
                    <PiArrowRightLight 
                        onClick={() => setUnitFromCurrent(unitFromCurrent + 1)}
                        className="arrow-date"
                        size={"2em"}
                        style={{cursor: "pointer"}}
                    />
                    {calendarPicker && <DatePicker dateRange={dates} updateDates={updateDates} />}
                    <div className="add-task-btn">
                        {addTask && <PiPlusCircleThin size={"3em"} className="btn-add" onClick={() => addTask()}/>}
                    </div>
                </div>

        </div>
        </>
    )
}