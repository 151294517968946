import React, {createContext, useState, useContext, useEffect} from 'react';
import type { User } from "../api/api"
import App from "../App";

export const AuthContext = createContext(null);

interface AuthProviderProps {
    children: any;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            setIsLoggedIn(true);
        }
    })

    return (
        // @ts-ignore
        <AuthContext.Provider value={{ isLoggedIn, setIsLoggedIn }}>
            {children}
        </AuthContext.Provider>
    )
}
