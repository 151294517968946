import {Link, useAsyncError} from "react-router-dom";
import {
    PiBookOpenTextThin,
    PiFileMagnifyingGlassThin,
    PiNotePencilThin,
    PiStackThin,
    PiXCircleThin
} from "react-icons/pi";
import {useState} from "react";

function Menu({ card, isMobile, processResearch }: any) {
    const [mobileMenu, toggleMobileMenu] = useState(false);

    return (
        <div className="spacrep-menu-container">
            <div className={`${isMobile ? "mobile-menu-toggle" : "spacrep-menu-cid"}`} tabIndex={0} onClick={() => toggleMobileMenu(!mobileMenu)}>{card.id}</div>
            {(!isMobile || (isMobile && mobileMenu)) && <div className="spacrep-menu">
                <Link to="/spaced_repetition/config" ><PiBookOpenTextThin size={"30px"} color={"#2c2c2c"} /></Link>
                <PiNotePencilThin className="edit-card" size={"30px"} color={"#2c2c2c"} />
                <PiFileMagnifyingGlassThin onClick={() => processResearch()} className="edit-card" size={"30px"} color={"#2c2c2c"} />
                <PiXCircleThin className="edit-card" size={"30px"} color={"#2c2c2c"} />
                <PiStackThin className="edit-card" size={"30px"} color={"#2c2c2c"} />
            </div>}
        </div>
    )

}

export {
    Menu
}