import {CardProps} from "../SRinterfaces";
import {ContentFormatter, sentenceParser} from "../utils";
import {SRCardBack, SRCardFront} from "./protocard";
import {useMemo} from "react";

export function Synonym({card, side}:CardProps) {
    const imgURIs = useMemo(() => {

        return card.images.filter((img) => img.trim()).map(img => <img src={`/images/cards/${img}`} />)
    }, [card])



    return (
        <div className="card synonym">
            {side && card && <>
                {SRCardFront(card, side, [side])}
                {SRCardBack(card, side, [side])}
            </>}
            <div className="card card-image">
                {imgURIs && imgURIs}
            </div>
        </div>
    )
}