import {CardProps} from "../SRinterfaces";
import {JSX, useMemo} from "react";
import {ContentFormatter} from "../utils";
import {SRCardBack, SRCardFront} from "./protocard";

export function Notation({ side, card }: CardProps) {



    const imgURIs = useMemo(() => {

        return card.images.filter((img) => img.trim()).map(img => <img src={`/images/cards/${img}`} />)
    }, [card])

    return (
        <div className="card notation">
            {side && card && <>
                {SRCardFront(card, side, [side])}
                {SRCardBack(card, side, [side])}
            </>}
            <div className="card card-image">
                {imgURIs && imgURIs}
            </div>
        </div>
    )
}