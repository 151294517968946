import {JSX, useMemo} from "react";
import katex from "katex";
import {Card} from "./SRinterfaces";
import {SRCard} from "./interface";

let latexDetectionRegex: RegExp = /\$.*?\$/g;
let latexExtractionRegex: RegExp = /\$(.*?)\$/g;
let codeDetectionRegex: RegExp = /\`.*?\`/g;
let codeExtractionRegex: RegExp = /\`(.*?)\`/g;

export const fetchAllRegex: Function = (regex: RegExp, text: string) => {
    let matches, output = [];
    while (matches = regex.exec(text)) {
        output.push(matches[1])
    }
    return output;
}



export const ContentFormatter = (deps: any[], card: SRCard, cardSide: string, currentSide: string, count: number = -99) => {
    return useMemo(() => {
        let newText: JSX.Element[] = [];
        if (card[cardSide].text.includes(";")) {
            // List items
            let sequence: JSX.Element;
            if (card['cardType'] !== "list" && (card['cardType'] !== "QA" && card[cardSide].type !== "list")) {
                sequence = <ul>{card[cardSide].text.split(";").map((item: string) => <li><p>{sentenceParser(item, currentSide, cardSide)}</p></li>)}</ul>
            } else {
                sequence = <ol>{card[cardSide].text.split(";").map((item: string) => <li><p>{sentenceParser(item, currentSide, cardSide)}</p></li>)}</ol>
            }
            newText.push(sequence)
        } else {
            // Text item
            newText = [sentenceParser(card[cardSide].text, currentSide, cardSide)]
        }

        const languageIn = card.cardType === "translation" && cardSide === "front"
        const languageOut = card.cardType === "translation" && cardSide === "back"
        const lang = languageIn ? card.languageIn : (languageOut ? card.languageOut : false)
        const progLang = card.cardType === "code" && cardSide === "front" ? card.programmingLanguage : false;
        const POSin = (card.cardType === "definition" || card.cardType === "translation") && cardSide === "front" && card.POSin.length > 0;
        const POSout = (card.cardType === "definition" || card.cardType === "translation") && cardSide === "back" && card.POSout.length > 0;

        return <>{currentSide === "front" && ((cardSide === "back" && card.reviewType === "front") || (cardSide === "front" && card.reviewType === "back"))
            ? <><span className="placeholder"> </span>{lang && <span className="lang">&nbsp;({lang})</span>}</> : <div className="full-text">{newText.map((el) => el)}{POSin && <span className="lang">&nbsp;[{card.POSin}]</span>}{POSout && <span className="lang">&nbsp;[{card.POSout}]</span>}{lang && <span className="lang">&nbsp;({lang})</span>}{progLang && <span className="lang">&nbsp;[{progLang}]</span>}</div>}
            {count > 0 && <span className="lang">&nbsp;[{count}]</span>}</>


    }, [...deps]);
}


export const sentenceParser = (oldText: string, currentSide: string, cardSide: string) => {
    let maskedFlag: boolean = (currentSide === "front" && cardSide === "back") || (currentSide === "back" && cardSide === "front");
    let newText: JSX.Element[] = [];
    let replaceValLatex: string | null = null;
    let replaceValCode: string | null = null;
    let textRepl: string =  oldText.replace(/[0-9]\.\s+/g, ''); 
    let latexAll: any[] = [];
    let codeAll: any[] = [];
    if (oldText.match(latexDetectionRegex)) {
        replaceValLatex = '{{L}}';
        textRepl = textRepl.replace(latexDetectionRegex, replaceValLatex);
        latexAll = fetchAllRegex(latexExtractionRegex, oldText);
    } else if (oldText.match(codeDetectionRegex)) {
        replaceValCode = '{{C}}';
        textRepl = textRepl.replace(codeDetectionRegex, replaceValCode);
        codeAll = fetchAllRegex(codeExtractionRegex, oldText);
    }

    let wordList = textRepl.split(" ");
    let punctuation;
    for (let i = 0; i < wordList.length; i++) {
        wordList[i] = wordList[i].trim();
        punctuation = null;
        if (wordList[i].includes("_")) {
            newText.push(<span className="placeholder"></span>);
            continue;
        }
        if (wordList[i][wordList[i].length-1] && (wordList[i][wordList[i].length-1].match(/,/) || wordList[i][wordList[i].length-1].match(/\./))) {
            punctuation = wordList[i][wordList[i].length-1]
        } else {
            punctuation = null;
        }
        if (replaceValLatex && wordList[i].match(replaceValLatex) && latexAll.length > 0) {
            let latexSpan = <span
                dangerouslySetInnerHTML={{__html: katex.renderToString(latexAll.shift(), {output: 'mathml'})}}></span>;            
            newText.push(latexSpan);
            if (punctuation) {
                newText.push(<span>{punctuation}</span>)
            }
        } else if (replaceValCode && wordList[i].match(replaceValCode) && codeAll && codeAll.length > 0) {
            let codeSpan = <code className="sl-code">{codeAll.shift()}</code>;
            newText.push(codeSpan);
            if (punctuation) {
                newText.push(<span>{punctuation}</span>)
            }
        } else {
            newText.push(<span>{wordList[i]}</span>);
        }
        if (i !== wordList.length - 1) {
            newText.push(<>&nbsp;</>)
        }
        
    }
    return <span className="">{newText}</span>;
}