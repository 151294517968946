import { PiArrowRightLight, PiArrowLeftLight } from "react-icons/pi";
import { DatePicker } from "../date_picker";
import { AgendaTimeUnit } from "./agenda_time_unit";
import { useEffect, useState } from "react";
import { EventObject } from "./event/event";
import { DateObject, WEEKDAYS } from "../planner";
import { DateHeader } from "../date_header";
import "./agenda_view.css";
import { getAllEvents } from "../../api/planner";

interface AgendaViewProps {
    setUnitFromCurrent: Function;
    unitFromCurrent: number;
    toggleCalendarPicker: Function;
    calendarPicker: boolean;
    dates: DateObject[];
    granularity: string;
    updateDates: Function;
    openPlanEventWindow: Function;
    events: any[];
}

export function AgendaView( { setUnitFromCurrent, unitFromCurrent, toggleCalendarPicker, calendarPicker, dates, granularity, updateDates, openPlanEventWindow, events }: AgendaViewProps ) {



    return (
        <div className={`${granularity}-display`}>
            <DateHeader 
                granularity={granularity}
                updateDates={updateDates}
                dates={dates}
                setUnitFromCurrent={setUnitFromCurrent}
                unitFromCurrent={unitFromCurrent}
            />
            <div className={`${granularity}`}>
                {granularity === "week"  && <div className="timeline" id="timeline-container">
                </div>}
                <div className={`${granularity}-container`} id={`${granularity}-container`}>
                    {dates && events && events.length > 0 && dates.map((date: DateObject, index: number) =>
                    <AgendaTimeUnit 
                        index={index+1}
                        key={index}
                        granularity={granularity} 
                        weekday={date['weekday']}
                        day={date['day']} 
                        date={date['date']} 
                        openPlanEventWindow={openPlanEventWindow} 
                        events={events.filter((e: EventObject) => e.date === date['date'])}
                    />
                )}
                </div>
            </div>
        </div>
    )
}