import type {Reference} from "./TrackingSystem";
import {RefObject, useEffect, useRef, useState} from "react";
import {addUnits, archiveRef, deactivateRef} from "../api/reference_tracking";
import {Link} from "react-router-dom";
import {AnimatePresence, motion} from "framer-motion";

interface TrackingUnitProps {
    reference: Reference;
    reload: Function;
    type: string;
    index: number;
    openDetailedView: Function;
}



const GRADIENTS: string[] = [
    '#73C8A9, #373B44',
    '#fdfc47, #24fe41',
    '#83a4d4, #b6fbff',
    '#fe8c00, #f83600',
    '#70e1f5, #ffd194',
    '#9d50bb, #6e48aa',
    '#f0c27b, #4b1248',
    '#ff4e50, #f9d423',
    '#fbd3e9, #bb377d',
    '#c9ffbf, #ffafbd',
    '#b993d6, #8ca6db',
    '#f2709c, #ff9472',
    '#de6262, #ffb88c',
    '#d53369, #cbad6d',
    '#f857a6, #ff5858',
    '#fc354c, #0abfbc',
    '#414d0b, #727a17',
    '#c04848, #480048',
    '#5f2c82, #49a09d',
    '#ec6f66, #f3a183',
    '#dae2f8, #d6a4a4',
    '#dc2424, #4a569d',
    '#24c6dc, #514a9d',
    '#283048, #859398',
    '#3d7eaa, #ffe47a',
    '#1cd8d2, #93edc7',
    '#757f9a, #d7dde8',
    '#5c258d, #4389a2',
    '#134e5e, #71b280',
    '#4776e6, #8e54e9',
    '#614385, #516395',
    '#eb3349, #f45c43',
    '#dd5e89, #f7bb97',
    '#4cb8c4, #3cd3ad',
    '#1fa2ff, #12d8fa, #a6ffcb',
    '#1d2b64, #f8cdda'
    ,'#aa076b, #61045f',
    '#ff512f, #dd2476',
    '#f09819, #edde5d',
    '#403b4a, #e7e9bb'
];



export function TrackingUnit({reference, reload, index, openDetailedView}: TrackingUnitProps) {
    const [menu, setMenu] = useState<string>();
    const [unfolded, setUnfolded] = useState(false);
    const unit = useRef<any>();

    function fold() {
        setMenu("");
        setUnfolded(false);
        openDetailedView(null);
    }

    function unFold() {
        // setUnfolded(true);
        openDetailedView(reference);
    }

    useEffect(() => {
        console.log(index)
    },[])

    return (// @ts-ignore
        <>
            {reference && index &&
                <AnimatePresence>
                <motion.div
                    ref={unit}
                    // onMouseLeave={() => fold()}
                    tabIndex={0}
                    className={`tracking-unit ${reference.type.replace(" ", "-")}`}
                    onClick={() => unFold()}
                    // onMouseEnter={() => setUnfolded(true)}
                    initial={{opacity:0}}
                    animate={{opacity:1}}
                    transition={{delay:index*0.03}}
                    style={{
                        // height: `${unfolded ? "730px" : "230px"}`,
                        gridRowEnd: `${unfolded ? "span 742px" : "span 243px"}`
                        // position: `${unfolded ? "absolute" : "relative"}`
                }}
                >
                {/*{menu && <TrackingUnitMenu reference={reference} reload={reload} type={menu} />}*/}
                <div tabIndex={0} onMouseEnter={() => setMenu("deactivate")} className="tracking-unit-ref-metadata">
                    <h5>{reference.reference_id && reference.reference_id}</h5>
            <h2 className="tracking-ref-title">{reference.title && reference.title}</h2>
            <div className="tracking-ref-author"><h3>{reference.authors && (reference.authors.includes("NA") ? reference.platform : reference.authors.join(", "))}</h3></div>
                </div>

            </motion.div>
                </AnimatePresence>}
        </>
    )
}