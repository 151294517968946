import {CardInterface, data, ImageInterface, rawCard, ReferenceInterface} from "./types";
import {Controller} from "./controller";
import {Reference} from "./reference";
import {JSXGenerator} from "./jsxGenerator";
import "./card.css";
import {postReviewProcessing, researchCard} from "../api/api";

class Image implements ImageInterface {

    url: string;
    fileFormat: string;

    constructor( url: string ) {
        this.url = url;
        this.fileFormat = this.url.split(".").splice(-1)[0];
    }

    toHTML() {

    }

}




export class Card implements CardInterface {

    id: number;
    path: string;
    type: string;
    fields: string[];
    tags: string[];
    images: Image[];
    easinessFactor: number;
    score: number|undefined;


    IMG_REGEX: RegExp = /\.(jpe?g|png|gif|bmp|webp|svg)$/i;
    reference: Reference|undefined;

    front: HTMLElement|null = null;
    back: HTMLElement|null = null

    reviewType: string;
    maxScore: number;

    constructor( controller: Controller, card: rawCard ) {

        this.id = card.cardID;
        if (this.id === 3314) console.log(card)
        this.reference = controller.mergeReferences(card);
        this.reviewType = card.reviewType;
        this.type = card.cardType;
        try {
            this.front = new JSXGenerator(this._calculateFront(card), this).html;
            this.back = new JSXGenerator(this._calculateBack(card), this).html;
        } catch (e) {
            console.log(e)
            console.log(card)
        }
        this.easinessFactor = card.easinessFactor;
        this.path = card.path;

        this.fields = card.field.split(";");
        this.tags = card.tags.split(";");
        this.images = card.data.images.split(";").map((url: string) => new Image(url));
        if (!card.data.back.text) console.log(card)
        this.maxScore = this.reviewType === "front" ? card.data.back.text.split(";").length : card.data.front.text.split(";").length;

    }

    _calculateFront( card: rawCard ): data {
        let data = this.reviewType === "front" ? card.data.front : card.data.back;
        if (this.type === "code" && this.reviewType === "back") return {...data, type: "code"};
        else if ((this.type === "visualization" || this.IMG_REGEX.test(data.text)) && this.reviewType === "back") return ({...data, type: "image"});
        else if (data.type === "list" || /\s*\d+\.\s*/gm.test(data.text)) return {...data, type: "list", text: data.text.replaceAll(/\s*\d+\.\s*/gm, '')}
        else if (this.type === "set" && this.reviewType === "back") return {...data, type: "set"}
        return {
            ...data, type: "text"
        };
    }

    _calculateBack( card: rawCard ): data {
        let data = this.reviewType === "front" ? card.data.back : card.data.front;
        if (this.type === "code" && this.reviewType === "front") return {...data, type: "code"}
        else if ((this.type === "set" && this.reviewType === "front") || /;-\s/gm.test(data.text)) return {...data, text: data.text.replaceAll(/-\s/gm, ''), type: "set"}
        else if ((this.type === "visualization" || this.IMG_REGEX.test(data.text)) && this.reviewType === "front") return ({...data, type: "image"});
        else if (data.type === "list" || /\s*\d+\.\s*/gm.test(data.text)) return {...data, type: "list", text: data.text.replaceAll(/\s*\d+\.\s*/gm, '')}
        return {
            ...data, type: "text"
        }
    }

    async research() {
        return await researchCard({ cardID: this.id, path: this.path });
    }

    async review( score: number ): Promise<number> {
        return await postReviewProcessing( {cardID: this.id, score: score / this.maxScore, easinessFactor: this.easinessFactor, reviewType: this.reviewType} );
    }

    ReactCard = ({ props }: any) => {
        return (
            <div>
                {props}
            </div>
        )
    }

    renderFront() {
        return <this.ReactCard props={this.front} />
    }

    renderBack() {
        return <this.ReactCard props={this.back} />
    }

    // Add ability to edit card

}