import "./taskboard.css";
import {useEffect, useRef, useState} from "react";
import React from "react";
import {Task} from "./task";
import {Link} from "react-router-dom";
import {AddTaskWindow} from "./add_task";
import {CATEGORIES} from "./constants";
import {useNavigate} from "react-router-dom";
import {Keyboard} from "../utils/keyboard";
import {Calendar} from "./calendar";
import {isMobile} from "../utils/mobile";


// @ts-ignore
function ColumnHeader({ category, index }) {
    return (
        <div className={"column-header header-" + index}>
            {category}
        </div>
    )
}

// @ts-ignore
function Column({ index }) {
    return (
        <div className={"column column-" + index}>
            <Task />
        </div>
    )
}

// @ts-ignore
function BoardUI({ alignment }) {


    return (
        <>
            <div className={"column-header-container " +alignment}>
            {CATEGORIES.map((cat, i) => <ColumnHeader category={cat} index={i} />)}
            </div>
            <div className={"board-interface " + alignment}>
            {CATEGORIES.map((cat: any, i) => <Column index={i} /> )}
            </div>
        </>
    )

}


export function TaskBoard() {
    const [alignment, setAlignment] = useState("");
    const [ui, setUI] = useState<React.RefObject<HTMLDivElement>>();
    const [addTaskWindow, setAddTaskWindow] = useState(false);
    const [mobile, setMobile] = useState(false);

    useEffect(() => {
        if (isMobile()) {
            setMobile(true)
        }
        setUI(React.createRef());
        window.addEventListener('resize', configureAlignment);

        return () => {
            window.removeEventListener('resize', configureAlignment);
        }
    }, []);

    useEffect(() => {
        configureAlignment();
    }, [ui]);

    function configureAlignment() {
        if (window.innerHeight > window.innerWidth) {
            setAlignment('horizontal');
        } else {
            setAlignment('vertical');
        }
    }

    const closeAddTaskWindow = () => {
        setAddTaskWindow(false);
    }

    return (
        <>
            {ui && <div ref={ui} className="taskboard">
                {/*<div className="board-nav">*/}
                {/*    <Keyboard keyEngravings={["household chore", "research", "programming"]} />*/}
                {/*    /!*<button><Link to="/">return</Link></button>*!/*/}
                {/*    /!*<button onClick={() => setAddTaskWindow(!addTaskWindow)}>Add task</button>*!/*/}
                {/*</div>*/}
                {/*{alignment && <BoardUI alignment={alignment} />}*/}
                {/*{addTaskWindow && <AddTaskWindow closeWindow={closeAddTaskWindow} />}*/}


                {mobile &&
                    <>
                    <div className="mobile-header">
                        Task board
                    </div>
                    <div className="mobile-ui">
                        <Calendar mobile={mobile} />
                        <div className="mobile-block mobile-todos-summary">

                        </div>
                    </div>
                    </>}
            </div>}

        </>
    )
}