import "./quote.css";
import {useState} from "react";
import {addQuote} from "../api/api";
import {GenericForm} from "../generic/form";


export function AddQuoteUI() {
    const [quote, setQuote] = useState<string>();
    const [author, setAuthor] = useState<string>();
    const [tags, setTags] = useState<string>();
    const [childKey, setChildKey] = useState<number>(0);
    const PROPERTIES = [{
        key: "quote",
        type: "textarea",
        options: []
    }, {
        key: "author",
        type: "text",
        options: []
    }, {
        key: "tags",
        type: "text",
        options: []
    }]

    function setProperty(property: string, value: string) {
        if (property === "tags") {
            setTags(value)
        } else if (property === "author") {
            setAuthor(value)
        } else if (property === "quote") {
            setQuote(value)
        }
    }

    function clearAll() {
        setQuote("");
        setAuthor("");
        setTags("");
        setChildKey((childKey) => childKey + 1)
    }

    async function submitQuote() {
        if (quote && quote.length > 1) {
            let source = author ?? "unknown";
            await addQuote(quote, source, tags).catch((err) => alert(err)).then((status) =>  status === 200 ? clearAll() : alert("Quote not saved."))
        } else {
            alert("Insert quotes")
        }
    }




    return (
        <GenericForm key={childKey} title={""} processForm={submitQuote} properties={PROPERTIES} setProperty={setProperty} />
    )
}