import {AddQuoteUI} from "./quote";
import "./quote_interface.css";
import {useEffect} from "react";
import {getRandomQuote} from "../api/api";
import {useState} from "react";

export interface QuoteObject {
    quote_id:number;
    quote:string;
    author:string;
}

export function QuoteInterface() {
    const [quote, setQuote] = useState<string>();
    const [author, setAuthor] = useState<string>();

    useEffect(() => {
        randomQuote();
    },[])

    async function randomQuote(){
        const data: Array<QuoteObject> = await getRandomQuote();
        setQuote(data[0].quote);
        setAuthor(data[0].author)
    }

    return (
        <div className="quote-ui">
            <div className="quote-display">
                {quote && <><div className="quote-text"><span className="quote-quote">"</span>{quote}<span className="quote-quote">"</span></div><br />
               {author && <span className="author-quote">- {author}</span>}</>}
            </div>
            <div className="quote-action-container">
                <AddQuoteUI />
            </div>

        </div>
    )
}