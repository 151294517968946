import {useEffect, useState} from "react";
import "./cardRepresentation.css";

export function CardRepresentation( { card, side }: any) {

    useEffect(() => {
        console.log(card)
    }, [])

    return (
        <div className="card-rep-container">
            {card.reference && <div className="card-rep-reference">
                <b>{card.reference.id}</b>&nbsp;&nbsp;<i>{card.reference.title}{!card.reference.title.endsWith(".") && "."}</i>
            </div>}
            <div className="card-rep-front">
                {card.renderFront()}
            </div>
            {side === "back" &&
                <div className="card-rep-back">
                    {card.renderBack()}
                </div>}
        </div>
    )

}