import {useEffect, useState} from "react";
import {Controller} from "./controller";
import {CardRepresentation} from "./cardRepresentation";
import {Card} from "./card";
import {getCard, selectFromCardsDue} from "../api/api";
import {EvaluationBar} from "./evaluationBar";
import {Menu} from "./menu";




function Console({ id, isMobile }: any) {
    const [controller, setController] = useState<Controller>();
    const [activeCard, setActiveCard] = useState<Card>();
    const [loading, setLoading] = useState(true);
    const [side, setSide] = useState("front");
    const [consecutiveFails, setConsecutiveFails] = useState(0);

    useEffect(() => {
        if (loading) {
            initializeConsole();
        }
    }, []);


    const initializeConsole = async () => {
        const data = await selectFromCardsDue();
        console.log(data)
        if (data && data.length > 0) setController(new Controller(data, setActiveCard, id));
        setLoading(false);
    }

    useEffect(() => {
        if (controller && (!controller.activeCard || (controller.activeCard && controller.activeCard.score))) setActiveCard(controller.nextCard());
    }, [controller]);

    const processReview = async (score: number) => {
        if (controller) {
            await controller.processReview(score).then((status) => {
                if (status === 200 || consecutiveFails === 3) {
                    setSide("front");
                    setActiveCard(controller.nextCard());
                    setConsecutiveFails(0);
                } else {
                    setConsecutiveFails((consecutiveFails) => consecutiveFails + 1);
                    alert("Failed to process review. Please try again.");
                }
            });

        }
    }

    const processResearch = async ( ) => {
        if (controller) {
            await controller.addResearchTag().then((status) => {
                if (status === 200) {
                    setSide("front");
                    setActiveCard(controller.nextCard());
                } else {
                    alert("Failed to add research tag. Please try again.");
                }
            })
        }
    }

    return (
        <>
            {activeCard && <Menu card={activeCard} isMobile={isMobile} processResearch={processResearch} />}
            <div className="spacrep-console">
                {activeCard && <CardRepresentation card={activeCard} side={side} />}
                <div className="spacrep-evaluation">
                    {activeCard && controller && activeCard.maxScore && <EvaluationBar activeCard={activeCard} processScore={processReview} side={side} switchSide={() => setSide("back")} />}
                </div>
                <div className="spacrep-card-type">
                    <div className="spacrep-circle"></div>
                    {activeCard && activeCard.type}
                </div>
            </div>
        </>
    )
}


export {
    Console
}