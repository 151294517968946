import {useEffect} from "react";
import "./generic_form.css";

interface PropertyObject {
    key: string;
    type: string;
    options: Array<string>;
}


interface FormProperties {
    title: string;
    processForm: Function;
    properties: Array<PropertyObject>;
    setProperty: Function;
}



export function GenericForm({ processForm, properties, setProperty, title }:FormProperties) {

    return (
        <div>
            <div className="form-container">
                <div className="form-container-header">
                    <h1>{title && title}</h1>
                </div>
                <div className="form">

                        {properties.map((prop: PropertyObject, index: number) =>
                            <>
                                <span className={`gf-${index}`}>{prop.key}</span>
                                {prop.type === "text" ?
                                    <input className={`gf-${index}`} onChange={(e) => setProperty(prop.key, e.target.value)} />
                                    :
                                    prop.type === "textarea" ?
                                        <textarea className={`gf-${index}`} spellCheck={false} onChange={(e) => setProperty(prop.key, e.target.value)}/>
                                        :
                                        <>{prop.hasOwnProperty("options") &&
                                        <select onChange={(e) => setProperty(prop.key, e.target.value)} className={`gf-${index}`}>
                                        {prop.options.map((refType: string) => <option>{refType}</option>)}
                                    </select>}</>}
                            </>
                        )}
                </div>
                <div className="form-container-footer">
                    <button onClick={(e) => processForm()} className="form-button">Submit</button>
                </div>

            </div>

        </div>
    )
}