import {Reference} from "./TrackingSystem";
import "./workspace.css";
import {ProgressBar} from "./progress_bar";
import {useState} from "react";
import {TrackingUnitMenu} from "./tracking_menu";
import { PiXSquareThin } from "react-icons/pi";
import {PomodoroTimer} from "./pomodoro_timer";


interface DetailedViewProps {
    reference: Reference;
    closeWindow: Function
}

const TABS = [""]

function Tab() {

    return (
        <div className="tab">

        </div>
    )
}



export function Workspace({ reference, closeWindow }: DetailedViewProps) {
    const [menu, setMenu] = useState<string|null>(null);

    function openMenu(e: any, label: string) {
        console.log(e)
        e.preventDefault();
        setMenu(label);
    }

    function determineClickLocation(e: any) {
        if (e.target.className.includes('detailed-view-container')) {
            closeWindow();
        }
    }

    function reload() {

    }

    return (
            <>
                {reference &&
                    <div
                        className="workspace-container"
                    >
                        <div className="workspace-exit"
                             onClick={() => closeWindow()}
                        >
                            <PiXSquareThin
                                className="workspace-exit-btn"
                                size={"2.5em"}
                            >
                            </PiXSquareThin>
                        </div>

                        {/*<PomodoroTimer />*/}
                        <div className={`detailed-view-ui ${reference.type}`}>
                            <div className="tab-container">

                            </div>
                            <div className="chapters">

                            </div>
                            <div className="tracking-progress">
                                {menu && <TrackingUnitMenu reference={reference} reload={reload} type={menu} />}
                                <div className="tracking-scroll">
                                <ProgressBar label={` read`} completed={reference.units_read} total={reference.total_units} level={"l-one"} openMenu={openMenu} />
                                <ProgressBar label={` keywords tracked`} completed={reference.units_keyword} total={reference.total_units} level={"l-two"}  openMenu={openMenu} />
                                <ProgressBar label={` processed for SR`} completed={reference.units_spaced_repetition} total={reference.total_units} level={"l-three"}  openMenu={openMenu} />
                                <ProgressBar label={` mindmaps created`} completed={reference.units_mindmap} total={reference.total_units} level={"l-four"}  openMenu={openMenu} />
                                <ProgressBar label={` feynman technique applied`} completed={reference.units_feynman} total={reference.total_units} level={"l-five"}  openMenu={openMenu} />
                                <ProgressBar label={` summarized`} completed={reference.units_summarized} total={reference.total_units} level={"l-six"}  openMenu={openMenu} />
                                {!(reference.units_exercise && reference.units_exercise.includes("NA")) && <ProgressBar label={` exercises completed`} completed={reference.units_exercise} total={reference.total_units} level={"l-four"}  openMenu={openMenu} />}
                                </div>
                            </div>
                        </div>
                    </div>
            }
            </>
    )
}