import {useEffect, useRef, useState} from "react";
import "./progress_bar.css";

interface ProgressBarProps {
    label: string;
    completed?: string[];
    total?: number;
    level:string;
    openMenu: Function;
}


export function ProgressBar({label, completed=[], total, level, openMenu}: ProgressBarProps) {
    // @ts-ignore
    const [progression, setProgression] = useState<string>("");
    const progress = useRef<any>();

    useEffect(() => {
        renderProgress();
    })

    useEffect(() => {
        if (progression === "100" && progress && progress.current) {
            progress.current.style["border-radius"] = "30px";
        }
        if (progress && progress.current) {
            // progress.current.style.background = `linear-gradient(60deg, ${GRADIENTS[Math.ceil(Math.random() * GRADIENTS.length-1)]})`;
        }
    },[progression]);

    function renderProgress() {
        if (total) {
            const prog = (completed && completed.length || 0)/total;
            setProgression((Math.floor(prog*100)).toString());
        }
    }

    return (
        <div className={`${level}`}>
            <div className={`tracking-label`}>{completed && completed.length || 0}/{total}{label && label}</div>
            <div>
                {openMenu && <div tabIndex={0} className="tracking-progress-bar" onClick={(e) => openMenu(e, label)}>
                    {total && progression && <div ref={progress} className={`progression ${label.split(" ")[label.split(" ").length - 1]}`} style={{width: progression + "%"}}></div>}
                </div>}
            </div>
        </div>
    )

}