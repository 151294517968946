import {useEffect, useMemo, useState} from "react"
import { getAllFields } from "../api/api";
import "./field_filter.css";
import {SRCard} from "./interface";

interface FieldFilterMenuProps {
    closeWindow: Function;
    activeCard: SRCard|null|undefined;
    activeFields: string[];
    selectField: Function;
    removeField: Function;
    allFieldsDeck: string[];
}

export function FieldFilterMenu({ closeWindow, activeCard, activeFields, selectField, removeField, allFieldsDeck }: FieldFilterMenuProps) {

    const displayFields = useMemo(() => {
        return (activeFields && activeFields.length > 0) ? [...allFieldsDeck.sort().filter((field) => !activeFields.includes(field))] : [...allFieldsDeck.sort()];
    },[allFieldsDeck])

    return (
        <div className="field-filter-blur">
            <div className="field-filter-menu">
                <div className="active-field-container">
                    {activeFields && activeFields.map((af: string) => <span className="active-field">{af}<span className="remove-field" onClick={(e) => removeField(af)}>&nbsp;x</span></span>)}
                </div>
                <div  className="field-selector">
                    <select onChange={(e) => selectField(e.target.value)}>
                        {displayFields && displayFields.map((item, i) => <option key={i} >{item}</option>)}
                    </select>
                </div>

                <div  className="cls-field-picker">
                    <button onClick={() => closeWindow()}>x</button>
                </div>
            </div>
        </div>
    )
}