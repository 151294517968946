import {AddTaskMenu} from "./addTaskMenu";
import "./menus.css";

export function CalendarMenu() {


    return (
        <div className="CalendarMenu">
            <div className="calender-nav">
                <AddTaskMenu />
            </div>
        </div>
    )
}