import {useState, useEffect, JSX, useMemo} from "react";
import {CardProps} from "../SRinterfaces";
import {ContentFormatter} from "../utils";
import {SRCardBack, SRCardFront} from "./protocard";


export function Definition({ side, card }: CardProps) {


    const count = useMemo(() => {
        let count = -99;
        if (card.reviewType === "front") {
            if (card.back.type === "list" || card.back.text.includes(";")) {
                count = card.back.text.split(";").length;
            }
        }
        return count;
    },[card])

    const imgURIs = useMemo(() => {

        return card.images.filter((img) => img.trim()).map(img => <img src={`/images/cards/${img}`} />)
    }, [card])

    return (
        <div className="card definition">
            {side && card && count && <>
                {SRCardFront(card, side, [side], count)}
                {SRCardBack(card, side, [side])}
            </>}
            {imgURIs && imgURIs}
        </div>
    )
}



