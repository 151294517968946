import {Card} from "./card";
import {rawCard, ReferenceInterface} from "./types";
import {Reference} from "./reference";


class Controller {

    cards: Card[] = [];
    references: ReferenceInterface[] = [];
    activeCard: Card|null = null;

    constructor( cards: any[] , setter: Function, id: number ) {
        this.cards = cards.map((card: rawCard) => new Card(this, card)) || [];
        this.activeCard = this.nextCard("id", id);
        setter(this.activeCard);
    }

    mergeReferences( card: rawCard): Reference|undefined {
        // Now only works for first reference
        if (!card.referenceIDs) return;
        let newReference: Reference;
        const referenceID = parseInt(card.referenceIDs.split(";")[0]);
        const refIndex = this._getReferenceIndex(referenceID);
        if (refIndex === -1) {
            newReference = new Reference(referenceID, card);
            this.references.push(newReference);
        } else {
            newReference = this.references[refIndex];
        }
        return newReference
    }

    _getReferenceIndex( id: number ): number {
        let index = -1;
        this.references.forEach((reference: Reference, i: number) => {
            if (reference.id === id) index = i;
        })
        return index;
    }

    async processReview ( score: number ) {
        if (this.activeCard) return await this.activeCard.review(score);
    }

    async addResearchTag () {
        if (this.activeCard) return await this.activeCard.research();
    }

    nextCard( property?: string, value?: any ): Card {
        const pickedIndex = property && value ? this._getIndexByProperty(property, value) : this._getRandomInt();
        const picked = this.cards[pickedIndex];
        this.cards.splice(pickedIndex, 1);
        return picked;
    }

    _getIndexByProperty( property: string, value: any ) {
        let ind = -1;
        this.cards.forEach((card: Card, i: number) => {
            // @ts-ignore
            if (card[property] === value) ind = i;
        })
        if (ind === -1) return this._getRandomInt();
        return ind;
    }

    _getRandomInt( ): number {
        return Math.floor(Math.random() * (this.cards.length - 1)) + 1;
    }

}

export {
    Controller
}
