import "./interface.css";
import {useEffect, useState} from "react";
import {CalendarDay} from "../day/day";
import {CalendarMenu} from "../menus/navigation";
import {Task} from "../task/task";
import {TaskObject} from "../task/task";

export function daysInMonth(month: number, year: number): number {
    return new Date(year, month, 0).getDate();
}

function arrayRange (start: number, stop: number, step: number = 1) {
    return Array.from({length: (stop-start) / step + 1},
        (value, index) => start + index * step);
}

const dummyTasks: Array<TaskObject> = [
    {
        task: "Clean the dishes",
        type:"chore",
        estimated_duration_min: 10
    },
    {
        task: "Blabla",
        type:"research",
        estimated_duration_min: 50
    }
]


export function CalendarInterface() {
    const [granularity, setGranularity] = useState("month");
    const [days, setDays] = useState<number[]>([]);
    const [activeDay, setActiveDay] = useState();

    useEffect(() => {
        recalculateDays();
    }, []);

    useEffect(() => {
        console.log(days)
    },[days])

    function recalculateDays() {
        const today = new Date();
        if (granularity === "month") {
            setDays([...arrayRange(1, daysInMonth(today.getMonth(), today.getFullYear()))])
        } else if (granularity === "week") {
            const firstDayOfWeek: number = new Date(today.setDate(today.getDate() - today.getDate())).getDay();
            setDays([...arrayRange(firstDayOfWeek, firstDayOfWeek + 7)])
        }

    }

    return (
        <div className="calendar-ui">
            <CalendarMenu />
        <div className="Interface">

           <div className="calendar">

            <div className="container">
                {days && days.length > 0 && days.map((day) => <CalendarDay date={day} setActiveDay={setActiveDay} />)}
            </div>
               <div className="view">
                   {activeDay &&
                       <div className="day-view">
                           {dummyTasks.map((task: TaskObject) => <Task task={task.task} type={task.type} estimated_duration_min={task.estimated_duration_min}/>)}
                       </div>}
               </div>
           </div>
        </div>
        </div>
    )
}