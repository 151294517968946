import {useState, useEffect, JSX} from "react";
import type {Card} from "../SRinterfaces";
import {List} from "./list";
import {Set} from "./set";
import {Definition} from "./definition";
import {Cloze} from "./cloze";
import {Translation} from "./translation";
import {Code} from "./code";
import {Visualization} from "./visualization";
import {Synonym} from "./synonym";
import {Command} from "./command";
import {Notation} from "./notation";
import {SRCard} from "../interface";
import "./protocard.css";
import {ContentFormatter} from "../utils";
import { QA } from "./QA";



interface ProtoCardProps {
    card: SRCard;
    refresh: Function;
    turnCard: Function;
    side: string;
    typeMode: boolean;
}

export function ProtoCard({ card, turnCard, refresh, typeMode, side }: ProtoCardProps) {

    useEffect(() => {
        console.log(card)
    }, [card]);


    return (
        <div className="protocard">
            {side &&
                        <>
                            {card && card['cardType'] === 'definition' && <Definition card={card} side={side} />}
                            {card && card['cardType'] === 'translation' && <Translation card={card}  side={side} />}
                            {card && card['cardType'] === 'list' && <List card={card}  side={side} />}
                            {card && card['cardType'] === 'set' && <Set card={card}  side={side} />}
                            {card && card['cardType'] === 'cloze' && <Cloze card={card}  side={side}/>}
                            {card && card['cardType'] === 'code' && <Code card={card}  side={side}/>}
                            {card && card['cardType'] === 'synonym' && <Synonym card={card}  side={side}/>}
                            {card && card['cardType'] === 'visualization' && <Visualization card={card}  side={side}/>}
                            {card && card['cardType'] === 'notation' && <Notation card={card}  side={side}/>}
                            {card && card['cardType'] === 'command' && <Command card={card}  side={side}/>}
                            {card && card['cardType'] === 'QA' && <QA card={card}  side={side}/>}
                        </>
            }
        </div>

    );
}


export function SRCardFront(card: SRCard, side: string, dependencies: any[], count?: number): JSX.Element {
    return <div className="front-card">{ContentFormatter([dependencies], card, "front", side, count)}</div>
}


export function SRCardBack(card: SRCard, side: string, dependencies: any[]): JSX.Element {
    return <div className="back-card">{ContentFormatter([dependencies], card, "back", side)}</div>

}