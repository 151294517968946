import {useEffect, useRef, useState} from "react";
import "./plan_event_window.css";
import "./slider.css";
import { EventObject } from "./event/event";

interface PlanEventWindowProps {
    closeWindow: Function;
    oldEvent: EventObject | null;
    newEvent: any;
}


export function PlanEventWindow({ closeWindow, newEvent, oldEvent }: PlanEventWindowProps ) {
    let window = useRef<any>();
    const [title, setTitle] = useState<string>();
    const [startHour, setStartHour] = useState<number>();
    const [startMinute, setStartMinute] = useState<number>();
    const [duration, setDuration] = useState<number>();
    const [endTime, setEndTime] = useState<string>();
    const [startTime, setStartTime] = useState<string>();
    const [recurring, setRecurring] = useState<boolean>(false);


    useEffect(() => {
        console.log("Opening event window..")
        function handleClickOutside(event: any) {
            if (window.current && !window.current.contains(event.target)) {
              closeWindow(); // Close the modal if click is outside
            }
          }
      
          // Add click listener
          document.addEventListener('mousedown', handleClickOutside);
          return () => {
            // Remove the listener on cleanup
            document.removeEventListener('mousedown', handleClickOutside);
          };
    },[])

    useEffect(() => {
        if (startMinute && startHour) {
            setStartTime(`${startHour.toString().padStart(2, '0')}:${startMinute.toString().padStart(2, '0')}`)
        }
    }, [startMinute, startHour])


    useEffect(() => {
        if (oldEvent) {
            console.log("Preexisting event..")
            setTitle(oldEvent.title);
            setStartHour(oldEvent.startHour);
            setStartMinute(oldEvent.startMinute);
            setDuration(oldEvent.duration);
            calculateEndTime(oldEvent.startHour, oldEvent.startMinute, oldEvent.duration);
        } else if (newEvent) {
            console.log("New event..")
            let allChildren = [...newEvent.target.parentElement.childNodes];
            allChildren = allChildren.map((child: any, index: number) => child === newEvent.target ? index : 0).filter((item: any) => item !== 0);
            setStartTime(`${allChildren[0].toString().padStart(2, '0')}:00`);
            setTitle("Event title");
            setEndTime(`${allChildren[0] + 1}:00`);
            setDuration(60);
        }

    },[oldEvent, newEvent])

    function calculateEndTime(startHour: number, startMinute: number, duration: number) {

    }

    function processEvent(e:any) {
        console.log(e)
    }

    function updateEndTime(e: any) {
        console.log(e.target.value)
        setEndTime(e.target.value.split(":")[0])
    }

    return (
        <div 
            ref={window}
            className="window plan-event-window"
            tabIndex={0}
            // onBlur={(e) => shouldCloseWindow(e)}
        >
                {title && <div className="plan-event-component">
                    <input className="plan-event-wdw title" type="text" defaultValue={title} id="title" onChange={(e) => setTitle(e.target.value)}/></div>}
            <div className="plan-event-component">
                <label>Date</label>
                <input type="date" /></div>
                {(oldEvent || newEvent) && startTime && startTime.length > 0 &&
                    <div className="plan-event-component">
                        <label>Start time</label>
                    <input className="plan-event-wdw time" type="time" defaultValue={startTime} />
                    </div>}
                {(oldEvent || newEvent) && endTime && endTime.length > 0 &&
                    <div className="plan-event-component">
                        <label>End time</label>
                        <input className="plan-event-wdw time" type="time" onChange={(e) => setEndTime(e.target.value)} defaultValue={endTime} />
                    </div>}
            <div className="plan-event-component">
            <label>Recurring</label>
            <label className="switch">
                <input type="checkbox" onChange={() => setRecurring(!recurring)} />
                <span className="slider round"></span>
            </label></div>
                {recurring && <div className="recurring-container">
                    <div className="recurring-type">Daily</div>
                    <div className="recurring-type">Weekly</div>
                    <div className="recurring-type">Every x day</div>
                    <div className="recurring-type">Monthly</div>
                    <div className="recurring-type">Every other week</div>
            </div>}
                <button className="plan-btn" onClick={(e) => processEvent(e)}>Add event</button>

        </div>
    )
}