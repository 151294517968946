import "./interfaceV2.css";
import {Menu} from "./menu";
import {useEffect, useState} from "react";
import {useMediaQuery} from "react-responsive";
import {Console} from "./console";
import {useParams} from "react-router-dom";

function InterfaceV2 () {
    const isMobile = useMediaQuery({ maxWidth: 400 })
    const [cards, setCards] = useState<any[]>([]);

    const params = useParams()

    useEffect(() => {
    }, [])

    return (
        <div className="spacrep-container" >
            {/* Loading component here */}
            <Console id={params.id ? parseInt(params.id) : null} isMobile={isMobile} />
        </div>
    )
}


export {
    InterfaceV2
};