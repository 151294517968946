import { useEffect, useState } from "react";
import "./css/spacedRepetition.css";
import {selectFromCardsDue, getCard} from "../api/api";
import { useParams, Link, useNavigate } from "react-router-dom";
import {ReviewMode} from "./reviewMode";
import "./interface.css";
import {processCards} from "../card_overview/CardOverview";

export interface CardSide {
  text: string;
  type: string;
}

interface Data {
  front: CardSide;
  back: CardSide;
  images: string;
  programmingLanguage?: string;
  languageIn?: string;
  languageOut?: string;
  POSin?: string;
  POSout?: string;
}

export interface SRCard {
  cardID: number;
  front: CardSide;
  back: CardSide;
  cardType: string;
  referenceID: number;
  referenceTitle: string;
  referenceAuthors: string[];
  reviewType: string;
  tags: string[];
  path: string;
  referenceLastUpdated: Date | null;
  referenceUrl: string;
  referenceType: string;
  easinessFactor: number;
  images: string[];
  fields: string[];
  programmingLanguage: string;
  languageIn: string;
  languageOut: string;
  POSin: string;
  POSout: string;
  [key: string]: any;
}

export interface CardData {
  cardID: number;
  data: Data;
  cardType: string;
  referenceIDs: string;
  easinessFactor: number;
  tags: string;
  authors: string;
  type: string;
  title: string;
  path: string;
  url: string;
  field:string;
  last_updated: string;
  reviewType: string;
}

export function SpacedRepetitionInterface() {
  const [cardsDue, setCardsDue] = useState<SRCard[]>([]);
  const [numberOfCardsDue, setNumberOfCardsDue] = useState<number>(0);
  const [reviewOn, setReviewOn] = useState<boolean>(false);
  const [noCards, setNoCards] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [filteredCards, setFilteredCards] = useState<SRCard[]>([]);

  let { id } = useParams();
  let  navigate  = useNavigate();

  useEffect(() => {
    console.log(cardsDue)
  },[cardsDue])


  useEffect(() => {
    if (id) {
      retrieveCardsByID();
    } else {
      retrieveCardsDue();
    }

    return () => {
      setNoCards(false);
      setNumberOfCardsDue(0);
      setCardsDue([]);
    }
  }, []);

  useEffect(() => {
    if (filter && filter.length > 0) {
      filterCards();
    }
  }, [filter]);


  async function retrieveCardsDue(fields: string[] = []): Promise<void> {
    console.log("Retrieving cards due..")
    const data = await selectFromCardsDue();
    setCardsDue([...processCards(data, fields)])
    setNumberOfCardsDue(data.length);
    data.length === 0 ? setNoCards(true) : setNoCards(false);
  }

  async function retrieveCardsByID(): Promise<void> {
    await getCard(Number(id)).then(data => {
      console.log(data)
      if (data && data.length > 0 && data[0]['cardID']) {
        setCardsDue([...processCards(data)]);
        setNumberOfCardsDue(1);
      } else {
        setCardsDue([]);
        setNoCards(true);
      }
    });
  }


  function filterCards() {
    let filteredCardsTemp = [];
    for (let card of cardsDue) {
      let testString: string = "";
      for (const [key, value] of Object.entries(card)) {
        testString += value + " "
      }
      if (testString.toLowerCase().includes(filter.toLowerCase())) {
        filteredCardsTemp.push(card)
      }
    }
    setFilteredCards([...filteredCardsTemp]);
  }



  return (
    <div className="interface">
      {cardsDue.length > 0 && !reviewOn &&
      <div className="start-window">
        <h2 className="text-3xl">Cards loaded..</h2>
        <div>
          <button className="font-mono" onClick={(e) => setReviewOn(true)}>start</button>
        </div>
      </div>}
      {reviewOn && <ReviewMode
              cardsDue={filter && filter.length > 0 ? filteredCards : cardsDue}
              filter={filter}
              updateCardsDue={setCardsDue}
              refresh={retrieveCardsDue}
              setFilter={setFilter}
              filterCardsByField={retrieveCardsDue}
          />}
    </div>
  );
}
