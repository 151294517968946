import "./event.css";
import { useEffect, useRef, useState } from "react";

export interface TaskObject {

}

export interface EventObject {
    event_id: number;
    title: string;
    startHour: number;
    startMinute: number;
    duration: number;
    type: string;
    date: string;
    tasks: any[];
    recurring: number;
    recurring_type: number;
}

interface EventProps {
    granularity: string;
    day: string;
    eventData?: EventObject;
    onUpdate:Function;
    openPlanEventWindow: Function;
}

export function Event({ granularity, day, eventData, onUpdate, openPlanEventWindow }: EventProps) {
    const [height, setHeight] = useState<number>(0);
    const [dragging, setDragging] = useState<string|boolean>(false);
    let eventBlock = useRef<any>();
    const [yTopBlock, setYTopBlock] = useState<number>();
    const [yBottomBlock, setYBottomBlock] = useState<number>();
    const [dayEvent, setDayEvent] = useState<EventObject>();
    const [showTitle, setShowTitle] = useState<boolean>(false);


    useEffect(() => {
        if (yTopBlock && granularity === "week") {
            setYBottomBlock(yTopBlock + height);
        }
    }, [height]);

    useEffect(() => {
        if (eventData && granularity === "week") {
            console.log("Generating events..")
            setDayEvent(eventData);
            setYTopBlock((eventData.startHour * 60) + (eventData.startMinute));
            setYBottomBlock(yTopBlock ? yTopBlock + (eventData.duration / 60) : 0);
            setHeight(eventData.duration);
        }
    },[])

    function handleDrag(e: any) {
        e.preventDefault();
        let rect = eventBlock.current.getBoundingClientRect();
        const isTopHalf = e.clientY - rect.top < rect.height / 2;

        if (!isTopHalf) {
            setDragging("down");
            let startHeight = height;
            const startY = e.clientY;
            const resize = (e:any) => {
                const newHeight = Math.max(Math.round((startHeight + e.clientY - startY) / 5) * 5, 5);
                setHeight(newHeight);
            } 
    
            const stopResize = () => {
                // Update the event's duration based on the final height
                // @ts-ignore
                setDayEvent({ ...dayEvent, duration: height });
                setDragging(false);
                document.removeEventListener('mousemove', resize);
                document.removeEventListener('mouseup', stopResize);
            };

            document.addEventListener('mousemove', resize);
            document.addEventListener('mouseup', stopResize);

        } else {
            let startHeight = rect.bottom - rect.top;
            const startY = e.clientY;
            const resize = (e:any) => {
                setDragging("up");
                const newHeight = Math.max(Math.round((startHeight - e.clientY + startY) / 5) * 5, 5);
                if (yBottomBlock) {
                    const newTop = yBottomBlock - newHeight;
                    setYTopBlock(newTop);
                    setHeight(newHeight);
                }
                
            } 
    
            const stopResize = () => {
                // Update the event's duration based on the final height
                // @ts-ignore
                setDayEvent({ ...dayEvent, duration: height });
                setDragging(false);
                document.removeEventListener('mousemove', resize);
                document.removeEventListener('mouseup', stopResize);
            };

            document.addEventListener('mousemove', resize);
            document.addEventListener('mouseup', stopResize);
            
        }
    }

    function generateHours() {
        if (yTopBlock && yBottomBlock) {
            const decimalHours = dragging === "up" ? yTopBlock / 60 : yBottomBlock / 60;
            const hours = Math.floor(decimalHours); // Extract whole hours
            const minutes = Math.round((decimalHours - hours) * 60); // Convert remainder to minutes

            const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
            return formattedTime;
        }
    }
    
    return (
        <>
        {granularity === "week" &&
            <>
            {dayEvent && dragging && yTopBlock && yBottomBlock && 
                <div 
                    tabIndex={0} 
                    className={`drag-time-display`}
                    style={{top: `${dragging === "up" ? `calc(${yTopBlock}px + .2em)` : `calc(${yBottomBlock}px - 1.7em)`}`}}
                >
                    {generateHours()}
                </div>}
            {eventData && yTopBlock && yBottomBlock && height && (
            <div 
                ref={eventBlock}
                tabIndex={0} 
                className={`${granularity}-event ${eventData.type}`} 
                style={{top: `${yTopBlock}px`, height: `${height}px`, cursor: `${dragging ? 'ns-resize' : 'pointer'}`, borderRadius: "8px"}} 
                onMouseDown={(e) => handleDrag(e)}
                onDoubleClick={() => openPlanEventWindow(dayEvent)}
                onMouseEnter={() => setShowTitle(true)}
                onMouseLeave={() => setShowTitle(false)}
                >
            {dayEvent && showTitle && dayEvent.title}
            </div>)}
            </>}
        {granularity === "month" && eventData && 
            <div 
                className={`${granularity}-event ${eventData.type}`} >
            </div>}
        </>
    )
}