import {useEffect, useState} from "react";
import {Reference} from "./TrackingSystem";
import {addUnits, archiveRef, deactivateRef} from "../api/reference_tracking";
import {Link} from "react-router-dom";
import "./tracking_menu.css";

interface TrackingUnitMenuProps {
    reference: Reference;
    reload: Function;
    type: string;
}


export function TrackingUnitMenu({reference, reload, type}: TrackingUnitMenuProps) {
    const [unitsRead, setUnitsRead] = useState<string>();
    const [unitsSummarized, setUnitsSummarized] = useState<string>();
    const [unitsFeynman, setUnitsFeynman] = useState<string>();
    const [unitsMindmap, setUnitsMindmap] = useState<string>();
    const [unitsKeyword, setUnitsKeyword] = useState<string>();
    const [unitsExercise, setUnitsExercise] = useState<string>();
    const [unitsSpacedRepetition, setUnitsSpacedRepetition] = useState<string>();

    useEffect(() => {
        if (reference) {
            setUnitsRead(reference.units_read ? reference.units_read.join(";") : "");
            setUnitsSummarized(reference.units_summarized ? reference.units_summarized.join(";") : "");
            setUnitsFeynman(reference.units_feynman ? reference.units_feynman.join(";") : "");
            setUnitsMindmap(reference.units_mindmap ? reference.units_mindmap.join(";") : "");
            setUnitsKeyword(reference.units_keyword ? reference.units_keyword.join(";") : "");
            setUnitsExercise(reference.units_exercise ? reference.units_exercise.join(";") : "");
            setUnitsSpacedRepetition(reference.units_spaced_repetition ? reference.units_spaced_repetition.join(";") : "")
        }
    }, []);

    async function deactivation(reference: Reference) {
        await deactivateRef(reference).then((status) =>  status === 200 ? reload() : alert("Failed to deactivate reference."));

    }

    async function archival() {
        await archiveRef(reference).then((status) =>  status === 200 ? reload() : alert("Failed to archive reference."));

    }

    async function processAdditionalUnits(key: string, e: any, extra: string[] = [], action: string) {
        if (e.key === "Enter") {
            if (extra.length > 0) {
                await processUnits(key, extra, action);
            } else {
                await processUnits(key, e.target.value.split(";"), action);
            }
        }
    }

    async function processUnits(key: string, value: string[], action: string) {
        console.log("Sending...")
        await addUnits(key, value, reference, action).then((status) => status === 200 ? reload() : alert("Units could not be added."))
    }

    // @ts-ignore
    return (
        <div className="tracking-unit-menu">
            {type && type === "deactivate" &&
                <div className="tracking-unit-menu-buttons">
                    <div className="tracking-unit-btn-container"><Link to={`/cards/from_reference/${reference.reference_id}`}><button className="tracking-unit-menu-button">view cards</button></Link></div>
                    <div className="tracking-unit-btn-container"><button className="tracking-unit-menu-button" onClick={() => archival()}>archive</button></div>
                    <div className="tracking-unit-btn-container"><button className="tracking-unit-menu-button" onClick={() => deactivation(reference)}>deactivate</button></div>
                </div>}
            {type && type.includes("read") &&
                <div className="tracking-unit-menu-add-unit">
                    <h3>{reference && reference.unit} read</h3>
                    <div>{reference.units_read ? reference.units_read.join(", ") : ""}</div>
                    <input
                        value={unitsRead}
                        type={"text"}
                        onChange={(e) => setUnitsRead(e.target.value)}
                        onKeyDown={(e) => processAdditionalUnits("units_read", e, [], "read")}
                    />
                </div>
            }
            {type && type.includes("summarized") &&
                <div className="tracking-unit-menu-add-unit">
                    <h3>{reference && reference.unit} summarized</h3>
                    <div>{reference.units_summarized ? reference.units_summarized.join(", ") : ""}</div>
                    <input
                        value={unitsSummarized}
                        type={"text"}
                        onChange={(e) => setUnitsSummarized(e.target.value)}
                        onKeyDown={(e) => processAdditionalUnits("units_summarized", e, [],"summarized")}
                    />
                </div>
            }
            {type && type.includes("SR") &&
                <div className="tracking-unit-menu-add-unit">
                    <h3>{reference && reference.unit} processed for spaced repetition</h3>
                    <div>{reference.units_spaced_repetition ? reference.units_spaced_repetition.join(", ") : ""}</div>
                    <input
                        value={unitsSpacedRepetition}
                        type={"text"}
                        onChange={(e) => setUnitsSpacedRepetition(e.target.value)}
                        onKeyDown={(e) => processAdditionalUnits("units_spaced_repetition", e, [], "spaced repetition")}
                    />
                </div>
            }
            {type && type.includes("mindmap") &&
                <div className="tracking-unit-menu-add-unit">
                    <h3>Mindmaps processed</h3>
                    <div>{reference.units_mindmap ? reference.units_mindmap.join(", ") : ""}</div>
                    <input
                        value={unitsMindmap}
                        onChange={(e) => setUnitsMindmap(e.target.value)}
                        onKeyDown={(e) => processAdditionalUnits("units_mindmap", e, [], "mindmap")}/>
                </div>
            }
            {type && type.includes("feynman") &&
                <div className="tracking-unit-menu-add-unit">
                    <h3>Feynman technique applied</h3>
                    <div>{reference.units_feynman ? reference.units_feynman.join(", ") : ""}</div>
                    <input
                        value={unitsFeynman}
                        onChange={(e) => setUnitsFeynman(e.target.value)}
                        onKeyDown={(e) => processAdditionalUnits("units_feynman", e, [], "feynman method")}/>
                </div>
            }
            {type && type.includes("keyword") &&
                <div className="tracking-unit-menu-add-unit">
                    <h3>Keywords tracked</h3>
                    <div>{reference.units_keyword ? reference.units_keyword.join(", ") : ""}</div>
                    <input
                        value={unitsKeyword}
                        onChange={(e) => setUnitsKeyword(e.target.value)}
                        onKeyDown={(e) => processAdditionalUnits("units_keyword", e, [], "keyword")}/>
                </div>
            }
            {type && type.includes("exercise") &&
                <div className="tracking-unit-menu-add-unit">
                    <h3>Exercises completed</h3>
                    <div>{reference.units_exercise ? reference.units_exercise.join(", ") : ""}</div>
                    <input
                        value={unitsExercise}
                        onChange={(e) => setUnitsExercise(e.target.value)}
                        onKeyDown={(e) => processAdditionalUnits("units_exercise", e,[], "exercise")}/>
                    <button className="tracking-unit-menu-button" onClick={() => processUnits("units_exercise", ["NA"],  "disabled exercise")}>Not Applicable</button>

                </div>
            }
        </div>
    )
}
