import { useState } from "react";

import "./task.css";

interface TaskProps {
    task_id: number;
    title: string;
    category: string;
    granularity: string;
}

export function Task({granularity, task_id, title, category}:TaskProps) {
    const [expanded, setExpanded] = useState(false);

    return <div className="task">
            <div className={`task-dot ${category}`}></div>
            <span className="task-title">{granularity !== "month" && title}</span>
        </div>
}