import "./planner.css"
import {useEffect, useRef, useState} from "react";
import {getAllHabits} from "../api/api";
import { EventObject } from "./agenda_view/event/event";
import { AgendaTimeUnit } from "./agenda_view/agenda_time_unit";
import { getAllEvents } from "../api/planner";
import { PiArrowLeftLight, PiArrowRightLight } from "react-icons/pi";
import { PlanEventWindow } from "./agenda_view/plan_event_window";
import { DatePicker } from "./date_picker";
import { AgendaView } from "./agenda_view/agenda_view";
import { TaskView } from "./task_view/task_view";
import {run} from "./test";
export const WEEKDAYS = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
const GRANULARITY = ["week", "month"];
const MODES = ["agenda", "tasks"];


export interface DateObject {
    [key: string]: string|number;
    date: string;
    day: string;
    weekday: number;
}


export function Planner() {
    const [granularity, setGranularity] = useState("week");
    const [mode, setMode] = useState("agenda");
    const [planEventWindow, setPlanEventWindow] = useState(false);
    const [events, setEvents] = useState<EventObject[]>([{
        event_id: 1, recurring:0, recurring_type:0, duration: 40, startHour:13, startMinute:45,tasks:[], title: 'pooping', date: '2024-03-09', type: "household"
    }]);
    const [dates, setDates] = useState<DateObject[]>([]);
    const [unitFromCurrent, setUnitFromCurrent] = useState<number>(0);
    const [planEventWindowObjectOld, setPlanEventWindowObjectOld] = useState<EventObject|null>(null);
    const [planEventWindowObjectNew, setPlanEventWindowObjectNew] = useState<EventObject|null>(null);
    const [calendarPicker, setCalendarPicker] = useState(false);

    useEffect(() => {
        getEvents();
        generateDates();
    }, [])

    useEffect(() => {
        const today = new Date();
        const multiplier = granularity === "week" ? 7 : 30;
        today.setDate(today.getDate() + (unitFromCurrent * multiplier));
        generateDates(today)
    },[unitFromCurrent]);

    useEffect(() => {
        setUnitFromCurrent(0);
        generateDates();
    },[granularity])


    useEffect(() => {
        console.log(events)
    },[events])

    useEffect(() => {
        // getEvents();
    },[dates])

    async function getEvents() {
        const processEvent = (event: any) => {
            event['date'] = event['date'].split("T")[0]
            event['tasks'] = event['tasks'] === null ? [] : event['tasks'].split(";");
            return event;
        }

        const events = await getAllEvents();
        if (events) {
            setEvents([...events.map((event: any) => processEvent(event))]);
        } else {
            setEvents([{
                event_id: 1, recurring:0, recurring_type:0, duration: 40, startHour:13, startMinute:45,tasks:[], title: 'pooping', date: '2024-03-09', type: "household"
            }])
        }
    }

    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        // getMonth() returns a zero-based index, so add 1 for the correct month and pad with leading zero if needed
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    function getDaysInMonth(date?: Date) {
        const today = date ? date : new Date();
        const year = today.getFullYear();
        const month = today.getMonth(); // Note: January is 0, February is 1, and so on.
        return new Date(year, month + 1, 0).getDate();
    }

    function getFirstDateOfMonth(date?: Date) {
        const today = date ? date : new Date();
        const year = today.getFullYear();
        const month = today.getMonth(); // Note: January is 0, February is 1, and so on.
        
        // Create a new date object for the first day of the current month
        return new Date(year, month, 1);
    }

    function generateDates(date?: Date) {
        let today = date ? date : new Date();

        const firstDayOfGranularity = granularity === "week" ? today.getDate() - today.getDay() + (today.getDay() === 0 ? -6 : 1) : getFirstDateOfMonth(today); // Adjusting so that Monday is the first day
        const dates: DateObject[] = [];
        const numberOfDates = granularity === "week" ? 7 : getDaysInMonth(today);

        for (let i = 0; i < numberOfDates; i++) {
            const newDate = {
                date: "",
                weekday: -99,
                day: ""
            }
            // Creating a new Date object for each day of the week
            if (granularity === "week") {
                // @ts-ignore
                const day = new Date(today.getFullYear(), today.getMonth(), firstDayOfGranularity + i);
                newDate['date'] = formatDate(day);
                newDate['weekday'] = i + 1;
                newDate['day'] = WEEKDAYS[i];
                dates.push(newDate);
            } else {
                const day = getFirstDateOfMonth(today);
                day.setDate(day.getDate() + i);
                newDate['date'] = formatDate(day);
                newDate['weekday'] = day.getDay()-1;
                newDate['day'] = WEEKDAYS[day.getDay()-1];
                dates.push(newDate);
            }

        }
        setDates([...dates]);
    }

    function openPlanEventWindow(e?: any) {
        if (e && e.target) {
            console.log(e)
            setPlanEventWindowObjectNew(e);
        } else if (e && e.title) {
            setPlanEventWindowObjectOld(e);
        }
        setPlanEventWindow(true);
    }

    function closeEventWindow() {
        setPlanEventWindow(false);
        setPlanEventWindowObjectNew(null);
        setPlanEventWindowObjectOld(null);
    }

    function updateDates(dates: Date[]) {
        generateDates(dates[0]);
    }

    function toggleCalendarPicker() {
        setCalendarPicker(!calendarPicker);
    }

    return (
        <div className="ui-dark">
            <div className="mode-switcher">
                {GRANULARITY.map((gran: string) => <button onClick={(e) => setGranularity(gran)}>{gran}</button>)}
                {MODES.map((mode: string) => <button onClick={(e) => setMode(mode)}>{mode}</button>)}
            </div>
            {events && <div className="planner-ui">
                {planEventWindow && (planEventWindowObjectOld || planEventWindowObjectNew ) && <PlanEventWindow closeWindow={closeEventWindow} newEvent={planEventWindowObjectNew} oldEvent={planEventWindowObjectOld} />}
                {mode === "tasks" && 
                <TaskView 
                    dates={dates}
                    granularity={granularity}
                    setUnitFromCurrent={setUnitFromCurrent}
                    unitFromCurrent={unitFromCurrent}
                    updateDates={updateDates}
                />}
                {mode === "agenda" && 
                <AgendaView 
                    dates={dates}
                    setUnitFromCurrent={setUnitFromCurrent}
                    unitFromCurrent={unitFromCurrent}
                    calendarPicker={calendarPicker}
                    toggleCalendarPicker={toggleCalendarPicker}
                    granularity={granularity}
                    updateDates={updateDates}
                    openPlanEventWindow={openPlanEventWindow}
                    events={events}
                />}
                </div>}

        </div>
    );
}