import {Link} from "react-router-dom";
import {
    PiBookOpenTextThin,
    PiFileMagnifyingGlassThin,
    PiNotePencilThin,
    PiStackThin,
    PiXCircleThin
} from "react-icons/pi";
import {deleteCard, formatCard, researchCard} from "../api/api";
import {SRCard} from "./interface";
import {useState} from "react";
import {FieldFilterMenu} from "./fieldFilterMenu";

interface ReviewMenuProps {
    activeCard: SRCard|undefined|null;
    configMenuUnfold: boolean;
    switchActiveCard: Function;
    refresh: Function;
    removeField: Function;
    selectField: Function;
    activeFields: string[];
    allFields: string[];
}

export function ReviewMenu ({ activeCard, configMenuUnfold, switchActiveCard, refresh, removeField, selectField, activeFields, allFields }:ReviewMenuProps) {
    const [fieldFilterMenu, setFieldFilterMenu] = useState<boolean>(false);
    const [editCardMode, setEditCardMode] = useState<boolean>(false);

    async function processDelete() {
        if (activeCard) {
            await deleteCard(activeCard).then((status) => status === 200 ? switchActiveCard() : alert("Failed to delete card."));
        }
    }

    async function processResearch() {
        await researchCard(activeCard).then((status) => status === 200 ? refresh() : alert("Failed to add research tag."));
    }

    async function processConfig(card: SRCard, fn: Function) {
        const response = await fn(card.cardID);
        console.log(response);
        if (response === 200) {
            refresh();
        }
    }

    function closeFieldWindow() {
        setFieldFilterMenu(false);
    }


    return (
        <div className="menu-sr">

            {fieldFilterMenu &&
                <FieldFilterMenu
                    closeWindow={closeFieldWindow}
                    activeCard={activeCard}
                    activeFields={activeFields}
                    selectField={selectField}
                    removeField={removeField}
                    allFieldsDeck={allFields}
                />}

            {/*{editCardMode && <EditCardMode card={activeCard} editCard={setEditCardMode} updateActiveCard={updateActiveCard} />}*/}

            <Link to="/spaced_repetition/config" ><PiBookOpenTextThin size={"30px"} color={"#2c2c2c"} /></Link>
            <PiNotePencilThin onClick={(e) => setEditCardMode(!editCardMode)} className="edit-card" size={"30px"} color={"#2c2c2c"} />
            <PiFileMagnifyingGlassThin onClick={(e) => processResearch()} className="edit-card" size={"30px"} color={"#2c2c2c"} />
            <PiXCircleThin onClick={() => processDelete()} className="edit-card" size={"30px"} color={"#2c2c2c"} />
            <PiStackThin onClick={() => setFieldFilterMenu(!fieldFilterMenu)} className="edit-card" size={"30px"} color={"#2c2c2c"} />
            {configMenuUnfold &&
                activeCard && <div className="config-menu-sr">
                    <button className="config-btn-sr" onClick={(e) => processConfig(activeCard, deleteCard)}>delete card</button>
                    <button className="config-btn-sr" onClick={(e) => processConfig(activeCard, formatCard)}>format card</button>
                    <button className="config-btn-sr" onClick={(e) => processConfig(activeCard, researchCard)}>research card</button>
                </div>}
        </div>
    )
}