import "./sr_config_ui.css";
import {useEffect, useMemo, useState} from "react";
import {AxisOptions, Chart} from "react-charts";
import katex from "katex";
import katexReplaceWithTex from "katex/contrib/katex2tex";

type DataSet = { date: Date, optimum_factor: number}



export function SRConfigUI() {

    useEffect(() => {

    }, [])

    const data = [
        {
            label: 'Series 1',
            data: generateSample()
        },
        {
            label: 'Series 2',
            data: generateSample()
        }
    ]

    function generateSample(n: number = 20): DataSet[] {
        let repsCompleted = 0;
        let repsLeft = n;
        let OF = 2.5;
        let interval = 1;
        let repDate = new Date();
        let result = []
        function runCycle() {
            const result = superMemo15Algorithm(repsCompleted, OF, interval, generateRandomScore());
            repsCompleted = repsCompleted + 1;
            repsLeft = repsLeft - 1;
            OF = result.optimum_factor;
            interval = result.interval;
            console.log(interval)
            repDate.setDate(repDate.getDate() + Math.floor(interval))
            return {
                date: repDate,
                optimum_factor: OF
            } as DataSet
        }
        while (repsCompleted < n) {
            result.push(runCycle());
        }
        console.log(result)
        return result;
    }

    function generateRandomScore() {
        return Math.floor(Math.random() + 1) * 5;
    }

    const primaryAxis = useMemo((): AxisOptions<DataSet> => ({
        getValue: series => series.date
    }),[]);

    const secondaryAxes = useMemo((): AxisOptions<DataSet>[] => [{
        getValue: series => series.optimum_factor
    }],[]);

    /**
     *
     * @param number_of_reps
     * @param optimum_factor A ratio of successive optimum intervals - dictate how much interval should increase in next review. Dependent on item difficulty and memory stability.
     * @param interval The interval between this repetition and the one before.
     * @param grade
     */
    function superMemo15Algorithm(number_of_reps: number = 1, optimum_factor: number = 2.5, interval: number = 1, grade: number) {
        if (number_of_reps == 0) {
            // optimum factor remains same
            number_of_reps = 1;
            interval = 1;
        } else {
            if (grade >= 3) {
                if (number_of_reps === 0) {

                }
                interval = interval * optimum_factor;
                number_of_reps = number_of_reps + 1;
                if (optimum_factor < 1.3) {
                    optimum_factor = 1.3;
                } else {
                    optimum_factor = optimum_factor + 0.1 - (5 - grade) * (0.08 + (5 - grade) * 0.02)
                }
            } else {
                number_of_reps = 1;
                interval = 1;
            }
        }
        const result = {
            number_of_reps: number_of_reps,
            optimum_factor: optimum_factor,
            interval: interval
        }
        console.log(result)
        return result;
    }

    function createLatex(input: string) {
        return <span
            dangerouslySetInnerHTML={{__html: katex.renderToString(input, {output: 'mathml'})}}></span>
    }

    return (
        <div className="sr-config-ui">
            <div className="sr-header">
                <h1>Spaced Repetition Explained</h1>
                <p>
                    Spaced repetition is a scientifically tested method to fight the forgetting curve.
                    The materials that are to be reviewed are divided into <i>collections</i>. Each collection consists of three types of elements: <i>items</i>, <i>topics</i>, and <i>tasks</i>.
                </p>
                <h3>Items</h3>
                <p>Atomic elements used to test your knowledge. Come in different formats, e.g. question and answer, picture recognition or multiple choice.</p>
                <h3>Topics</h3>
                <p>Pages of new information to learn. Require passive reviewing.</p>
                <h3>Tasks</h3>
                <p>To dos</p>
                <p>
                    SM-15 assumes that memory declines exponentially. For any item {createLatex("k")}, there exists {createLatex("f_k")} such that for any given time {createLatex("t")}, the probability one could recall the item after a correct
                    recall is {createLatex("P_k(t) = e^{f_k t}")}.

                </p>
                <p>
                    <a href={"http://super-memory.com/archive/help16/topic.htm"}>super-memory.com/archive/help16/topic.htm</a>
                </p>
            </div>
            {/*<Chart*/}
            {/*    options={{data, primaryAxis, secondaryAxes}}*/}
            {/*/>*/}
        </div>
    )
}